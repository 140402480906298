/* tslint:disable */
/* eslint-disable */
/**
 * kraken
 * The core component of kraken-project
 *
 * The version of the OpenAPI document: 0.3.5
 * Contact: git@omikron.dev
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiErrorResponse,
  CreateServiceRequest,
  FullAggregationSource,
  FullService,
  GetAllServicesQuery,
  ListFindings,
  ServiceRelations,
  ServiceResultsPage,
  UpdateServiceRequest,
  UuidResponse,
} from '../models';
import {
    ApiErrorResponseFromJSON,
    ApiErrorResponseToJSON,
    CreateServiceRequestFromJSON,
    CreateServiceRequestToJSON,
    FullAggregationSourceFromJSON,
    FullAggregationSourceToJSON,
    FullServiceFromJSON,
    FullServiceToJSON,
    GetAllServicesQueryFromJSON,
    GetAllServicesQueryToJSON,
    ListFindingsFromJSON,
    ListFindingsToJSON,
    ServiceRelationsFromJSON,
    ServiceRelationsToJSON,
    ServiceResultsPageFromJSON,
    ServiceResultsPageToJSON,
    UpdateServiceRequestFromJSON,
    UpdateServiceRequestToJSON,
    UuidResponseFromJSON,
    UuidResponseToJSON,
} from '../models';

export interface CreateServiceOperationRequest {
    uuid: string;
    createServiceRequest: CreateServiceRequest;
}

export interface DeleteServiceRequest {
    wUuid: string;
    sUuid: string;
}

export interface GetAllServicesRequest {
    uuid: string;
    getAllServicesQuery: GetAllServicesQuery;
}

export interface GetServiceRequest {
    wUuid: string;
    sUuid: string;
}

export interface GetServiceFindingsRequest {
    wUuid: string;
    sUuid: string;
}

export interface GetServiceRelationsRequest {
    wUuid: string;
    sUuid: string;
}

export interface GetServiceSourcesRequest {
    wUuid: string;
    sUuid: string;
}

export interface UpdateServiceOperationRequest {
    wUuid: string;
    sUuid: string;
    updateServiceRequest: UpdateServiceRequest;
}

/**
 * 
 */
export class ServicesApi extends runtime.BaseAPI {

    /**
     * Manually add a service
     */
    async createServiceRaw(requestParameters: CreateServiceOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UuidResponse>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling createService.');
        }

        if (requestParameters.createServiceRequest === null || requestParameters.createServiceRequest === undefined) {
            throw new runtime.RequiredError('createServiceRequest','Required parameter requestParameters.createServiceRequest was null or undefined when calling createService.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/workspaces/{uuid}/services`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateServiceRequestToJSON(requestParameters.createServiceRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UuidResponseFromJSON(jsonValue));
    }

    /**
     * Manually add a service
     */
    async createService(requestParameters: CreateServiceOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UuidResponse> {
        const response = await this.createServiceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * This only deletes the aggregation. The raw results are still in place
     * Delete the service
     */
    async deleteServiceRaw(requestParameters: DeleteServiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.wUuid === null || requestParameters.wUuid === undefined) {
            throw new runtime.RequiredError('wUuid','Required parameter requestParameters.wUuid was null or undefined when calling deleteService.');
        }

        if (requestParameters.sUuid === null || requestParameters.sUuid === undefined) {
            throw new runtime.RequiredError('sUuid','Required parameter requestParameters.sUuid was null or undefined when calling deleteService.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/workspaces/{w_uuid}/services/{s_uuid}`.replace(`{${"w_uuid"}}`, encodeURIComponent(String(requestParameters.wUuid))).replace(`{${"s_uuid"}}`, encodeURIComponent(String(requestParameters.sUuid))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * This only deletes the aggregation. The raw results are still in place
     * Delete the service
     */
    async deleteService(requestParameters: DeleteServiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteServiceRaw(requestParameters, initOverrides);
    }

    /**
     * List the services of a workspace
     */
    async getAllServicesRaw(requestParameters: GetAllServicesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ServiceResultsPage>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling getAllServices.');
        }

        if (requestParameters.getAllServicesQuery === null || requestParameters.getAllServicesQuery === undefined) {
            throw new runtime.RequiredError('getAllServicesQuery','Required parameter requestParameters.getAllServicesQuery was null or undefined when calling getAllServices.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/workspaces/{uuid}/services/all`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetAllServicesQueryToJSON(requestParameters.getAllServicesQuery),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ServiceResultsPageFromJSON(jsonValue));
    }

    /**
     * List the services of a workspace
     */
    async getAllServices(requestParameters: GetAllServicesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ServiceResultsPage> {
        const response = await this.getAllServicesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieve all information about a single service
     */
    async getServiceRaw(requestParameters: GetServiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FullService>> {
        if (requestParameters.wUuid === null || requestParameters.wUuid === undefined) {
            throw new runtime.RequiredError('wUuid','Required parameter requestParameters.wUuid was null or undefined when calling getService.');
        }

        if (requestParameters.sUuid === null || requestParameters.sUuid === undefined) {
            throw new runtime.RequiredError('sUuid','Required parameter requestParameters.sUuid was null or undefined when calling getService.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/workspaces/{w_uuid}/services/{s_uuid}`.replace(`{${"w_uuid"}}`, encodeURIComponent(String(requestParameters.wUuid))).replace(`{${"s_uuid"}}`, encodeURIComponent(String(requestParameters.sUuid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FullServiceFromJSON(jsonValue));
    }

    /**
     * Retrieve all information about a single service
     */
    async getService(requestParameters: GetServiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FullService> {
        const response = await this.getServiceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a service\'s findings
     */
    async getServiceFindingsRaw(requestParameters: GetServiceFindingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListFindings>> {
        if (requestParameters.wUuid === null || requestParameters.wUuid === undefined) {
            throw new runtime.RequiredError('wUuid','Required parameter requestParameters.wUuid was null or undefined when calling getServiceFindings.');
        }

        if (requestParameters.sUuid === null || requestParameters.sUuid === undefined) {
            throw new runtime.RequiredError('sUuid','Required parameter requestParameters.sUuid was null or undefined when calling getServiceFindings.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/workspaces/{w_uuid}/services/{s_uuid}/findings`.replace(`{${"w_uuid"}}`, encodeURIComponent(String(requestParameters.wUuid))).replace(`{${"s_uuid"}}`, encodeURIComponent(String(requestParameters.sUuid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListFindingsFromJSON(jsonValue));
    }

    /**
     * Get a service\'s findings
     */
    async getServiceFindings(requestParameters: GetServiceFindingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListFindings> {
        const response = await this.getServiceFindingsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a service\'s direct relations
     */
    async getServiceRelationsRaw(requestParameters: GetServiceRelationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ServiceRelations>> {
        if (requestParameters.wUuid === null || requestParameters.wUuid === undefined) {
            throw new runtime.RequiredError('wUuid','Required parameter requestParameters.wUuid was null or undefined when calling getServiceRelations.');
        }

        if (requestParameters.sUuid === null || requestParameters.sUuid === undefined) {
            throw new runtime.RequiredError('sUuid','Required parameter requestParameters.sUuid was null or undefined when calling getServiceRelations.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/workspaces/{w_uuid}/services/{s_uuid}/relations`.replace(`{${"w_uuid"}}`, encodeURIComponent(String(requestParameters.wUuid))).replace(`{${"s_uuid"}}`, encodeURIComponent(String(requestParameters.sUuid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ServiceRelationsFromJSON(jsonValue));
    }

    /**
     * Get a service\'s direct relations
     */
    async getServiceRelations(requestParameters: GetServiceRelationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ServiceRelations> {
        const response = await this.getServiceRelationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all data sources which referenced this service
     */
    async getServiceSourcesRaw(requestParameters: GetServiceSourcesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FullAggregationSource>> {
        if (requestParameters.wUuid === null || requestParameters.wUuid === undefined) {
            throw new runtime.RequiredError('wUuid','Required parameter requestParameters.wUuid was null or undefined when calling getServiceSources.');
        }

        if (requestParameters.sUuid === null || requestParameters.sUuid === undefined) {
            throw new runtime.RequiredError('sUuid','Required parameter requestParameters.sUuid was null or undefined when calling getServiceSources.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/workspaces/{w_uuid}/services/{s_uuid}/sources`.replace(`{${"w_uuid"}}`, encodeURIComponent(String(requestParameters.wUuid))).replace(`{${"s_uuid"}}`, encodeURIComponent(String(requestParameters.sUuid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FullAggregationSourceFromJSON(jsonValue));
    }

    /**
     * Get all data sources which referenced this service
     */
    async getServiceSources(requestParameters: GetServiceSourcesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FullAggregationSource> {
        const response = await this.getServiceSourcesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * You must include at least on parameter
     * Update a service
     */
    async updateServiceRaw(requestParameters: UpdateServiceOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.wUuid === null || requestParameters.wUuid === undefined) {
            throw new runtime.RequiredError('wUuid','Required parameter requestParameters.wUuid was null or undefined when calling updateService.');
        }

        if (requestParameters.sUuid === null || requestParameters.sUuid === undefined) {
            throw new runtime.RequiredError('sUuid','Required parameter requestParameters.sUuid was null or undefined when calling updateService.');
        }

        if (requestParameters.updateServiceRequest === null || requestParameters.updateServiceRequest === undefined) {
            throw new runtime.RequiredError('updateServiceRequest','Required parameter requestParameters.updateServiceRequest was null or undefined when calling updateService.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/workspaces/{w_uuid}/services/{s_uuid}`.replace(`{${"w_uuid"}}`, encodeURIComponent(String(requestParameters.wUuid))).replace(`{${"s_uuid"}}`, encodeURIComponent(String(requestParameters.sUuid))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateServiceRequestToJSON(requestParameters.updateServiceRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * You must include at least on parameter
     * Update a service
     */
    async updateService(requestParameters: UpdateServiceOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateServiceRaw(requestParameters, initOverrides);
    }

}
