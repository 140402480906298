import React from "react";

export default function PlusIcon(props: React.HTMLAttributes<HTMLDivElement>) {
    return (
        <div className={"icon"} {...props}>
            <svg className={"neon"} viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M512 288a32 32 0 0 0 32-32V192a32 32 0 0 0-64 0v64a32 32 0 0 0 32 32zM494.08 90.56a24.32 24.32 0 0 0 5.76 2.88l5.76 2.56h12.48a19.84 19.84 0 0 0 6.08-1.92 17.92 17.92 0 0 0 5.44-2.88l4.8-3.84A32 32 0 0 0 544 64a32 32 0 0 0-2.56-12.16 29.12 29.12 0 0 0-7.04-10.56l-4.8-3.84a17.92 17.92 0 0 0-5.44-2.88 19.52 19.52 0 0 0-6.08-2.56 27.2 27.2 0 0 0-12.48 0 20.16 20.16 0 0 0-5.76 1.92 24.32 24.32 0 0 0-5.76 2.88l-4.8 3.84a36.8 36.8 0 0 0-6.72 10.56 26.56 26.56 0 0 0-2.56 12.8 32 32 0 0 0 9.28 22.72zM960 480H544v-96a32 32 0 0 0-64 0v96H64a32 32 0 0 0 0 64h416v416a32 32 0 0 0 64 0V544h416a32 32 0 0 0 0-64z"
                    fill="#0cf"
                />
            </svg>
        </div>
    );
}
