/* tslint:disable */
/* eslint-disable */
/**
 * kraken
 * The core component of kraken-project
 *
 * The version of the OpenAPI document: 0.3.5
 * Contact: git@omikron.dev
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SimpleDomain } from './SimpleDomain';
import {
    SimpleDomainFromJSON,
    SimpleDomainFromJSONTyped,
    SimpleDomainToJSON,
} from './SimpleDomain';
import type { SimpleHost } from './SimpleHost';
import {
    SimpleHostFromJSON,
    SimpleHostFromJSONTyped,
    SimpleHostToJSON,
} from './SimpleHost';
import type { SimpleHttpService } from './SimpleHttpService';
import {
    SimpleHttpServiceFromJSON,
    SimpleHttpServiceFromJSONTyped,
    SimpleHttpServiceToJSON,
} from './SimpleHttpService';

/**
 * A domain's direct relations
 * @export
 * @interface DomainRelations
 */
export interface DomainRelations {
    /**
     * All domains which contain a `CNAME` record with this domain
     * @type {Array<SimpleDomain>}
     * @memberof DomainRelations
     */
    sourceDomains: Array<SimpleDomain>;
    /**
     * All domains this domain has `CNAME` records to
     * @type {Array<SimpleDomain>}
     * @memberof DomainRelations
     */
    targetDomains: Array<SimpleDomain>;
    /**
     * All hosts this domain has an `A` or `AAAA` record for
     * @type {Array<SimpleHost>}
     * @memberof DomainRelations
     */
    directHosts: Array<SimpleHost>;
    /**
     * All hosts any `target_domains` resolves to
     * @type {Array<SimpleHost>}
     * @memberof DomainRelations
     */
    indirectHosts: Array<SimpleHost>;
    /**
     * This domain's http services
     * @type {Array<SimpleHttpService>}
     * @memberof DomainRelations
     */
    httpServices: Array<SimpleHttpService>;
}

/**
 * Check if a given object implements the DomainRelations interface.
 */
export function instanceOfDomainRelations(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "sourceDomains" in value;
    isInstance = isInstance && "targetDomains" in value;
    isInstance = isInstance && "directHosts" in value;
    isInstance = isInstance && "indirectHosts" in value;
    isInstance = isInstance && "httpServices" in value;

    return isInstance;
}

export function DomainRelationsFromJSON(json: any): DomainRelations {
    return DomainRelationsFromJSONTyped(json, false);
}

export function DomainRelationsFromJSONTyped(json: any, ignoreDiscriminator: boolean): DomainRelations {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sourceDomains': ((json['source_domains'] as Array<any>).map(SimpleDomainFromJSON)),
        'targetDomains': ((json['target_domains'] as Array<any>).map(SimpleDomainFromJSON)),
        'directHosts': ((json['direct_hosts'] as Array<any>).map(SimpleHostFromJSON)),
        'indirectHosts': ((json['indirect_hosts'] as Array<any>).map(SimpleHostFromJSON)),
        'httpServices': ((json['http_services'] as Array<any>).map(SimpleHttpServiceFromJSON)),
    };
}

export function DomainRelationsToJSON(value?: DomainRelations | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'source_domains': ((value.sourceDomains as Array<any>).map(SimpleDomainToJSON)),
        'target_domains': ((value.targetDomains as Array<any>).map(SimpleDomainToJSON)),
        'direct_hosts': ((value.directHosts as Array<any>).map(SimpleHostToJSON)),
        'indirect_hosts': ((value.indirectHosts as Array<any>).map(SimpleHostToJSON)),
        'http_services': ((value.httpServices as Array<any>).map(SimpleHttpServiceToJSON)),
    };
}

