/* tslint:disable */
/* eslint-disable */
/**
 * kraken
 * The core component of kraken-project
 *
 * The version of the OpenAPI document: 0.3.5
 * Contact: git@omikron.dev
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ManualHostCertainty } from './ManualHostCertainty';
import {
    ManualHostCertaintyFromJSON,
    ManualHostCertaintyFromJSONTyped,
    ManualHostCertaintyToJSON,
} from './ManualHostCertainty';

/**
 * The request to manually add a host
 * @export
 * @interface CreateHostRequest
 */
export interface CreateHostRequest {
    /**
     * The host's ip address
     * 
     * Also accepts a network and adds all its addresses
     * @type {string}
     * @memberof CreateHostRequest
     */
    ipAddr: string;
    /**
     * 
     * @type {ManualHostCertainty}
     * @memberof CreateHostRequest
     */
    certainty: ManualHostCertainty;
}

/**
 * Check if a given object implements the CreateHostRequest interface.
 */
export function instanceOfCreateHostRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "ipAddr" in value;
    isInstance = isInstance && "certainty" in value;

    return isInstance;
}

export function CreateHostRequestFromJSON(json: any): CreateHostRequest {
    return CreateHostRequestFromJSONTyped(json, false);
}

export function CreateHostRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateHostRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ipAddr': json['ip_addr'],
        'certainty': ManualHostCertaintyFromJSON(json['certainty']),
    };
}

export function CreateHostRequestToJSON(value?: CreateHostRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ip_addr': value.ipAddr,
        'certainty': ManualHostCertaintyToJSON(value.certainty),
    };
}

