/* tslint:disable */
/* eslint-disable */
/**
 * kraken
 * The core component of kraken-project
 *
 * The version of the OpenAPI document: 0.3.5
 * Contact: git@omikron.dev
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiErrorResponse,
  CreateWordlistRequest,
  ListWordlistsAdmin,
  UpdateWordlistRequest,
  UuidResponse,
} from '../models';
import {
    ApiErrorResponseFromJSON,
    ApiErrorResponseToJSON,
    CreateWordlistRequestFromJSON,
    CreateWordlistRequestToJSON,
    ListWordlistsAdminFromJSON,
    ListWordlistsAdminToJSON,
    UpdateWordlistRequestFromJSON,
    UpdateWordlistRequestToJSON,
    UuidResponseFromJSON,
    UuidResponseToJSON,
} from '../models';

export interface CreateWordlistAdminRequest {
    createWordlistRequest: CreateWordlistRequest;
}

export interface DeleteWordlistAdminRequest {
    uuid: string;
}

export interface UpdateWordlistAdminRequest {
    uuid: string;
    updateWordlistRequest: UpdateWordlistRequest;
}

/**
 * 
 */
export class WordlistManagementApi extends runtime.BaseAPI {

    /**
     * Create a new wordlist
     */
    async createWordlistAdminRaw(requestParameters: CreateWordlistAdminRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UuidResponse>> {
        if (requestParameters.createWordlistRequest === null || requestParameters.createWordlistRequest === undefined) {
            throw new runtime.RequiredError('createWordlistRequest','Required parameter requestParameters.createWordlistRequest was null or undefined when calling createWordlistAdmin.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/admin/wordlists`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateWordlistRequestToJSON(requestParameters.createWordlistRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UuidResponseFromJSON(jsonValue));
    }

    /**
     * Create a new wordlist
     */
    async createWordlistAdmin(requestParameters: CreateWordlistAdminRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UuidResponse> {
        const response = await this.createWordlistAdminRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete an existing wordlist
     */
    async deleteWordlistAdminRaw(requestParameters: DeleteWordlistAdminRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling deleteWordlistAdmin.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/admin/wordlists/{uuid}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete an existing wordlist
     */
    async deleteWordlistAdmin(requestParameters: DeleteWordlistAdminRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteWordlistAdminRaw(requestParameters, initOverrides);
    }

    /**
     * Get a list of all wordlists including their paths
     */
    async getAllWordlistsAdminRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListWordlistsAdmin>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/admin/wordlists`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListWordlistsAdminFromJSON(jsonValue));
    }

    /**
     * Get a list of all wordlists including their paths
     */
    async getAllWordlistsAdmin(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListWordlistsAdmin> {
        const response = await this.getAllWordlistsAdminRaw(initOverrides);
        return await response.value();
    }

    /**
     * Update an existing wordlist
     */
    async updateWordlistAdminRaw(requestParameters: UpdateWordlistAdminRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling updateWordlistAdmin.');
        }

        if (requestParameters.updateWordlistRequest === null || requestParameters.updateWordlistRequest === undefined) {
            throw new runtime.RequiredError('updateWordlistRequest','Required parameter requestParameters.updateWordlistRequest was null or undefined when calling updateWordlistAdmin.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/admin/wordlists/{uuid}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateWordlistRequestToJSON(requestParameters.updateWordlistRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update an existing wordlist
     */
    async updateWordlistAdmin(requestParameters: UpdateWordlistAdminRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateWordlistAdminRaw(requestParameters, initOverrides);
    }

}
