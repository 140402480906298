/* tslint:disable */
/* eslint-disable */
/**
 * kraken
 * The core component of kraken-project
 *
 * The version of the OpenAPI document: 0.3.5
 * Contact: git@omikron.dev
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * The request to update an affected object's details
 * @export
 * @interface UpdateFindingAffectedRequest
 */
export interface UpdateFindingAffectedRequest {
    /**
     * A screenshot
     * 
     * The file must have been uploaded through the image upload.
     * @type {string}
     * @memberof UpdateFindingAffectedRequest
     */
    screenshot?: string | null;
    /**
     * A log file
     * @type {string}
     * @memberof UpdateFindingAffectedRequest
     */
    logFile?: string | null;
}

/**
 * Check if a given object implements the UpdateFindingAffectedRequest interface.
 */
export function instanceOfUpdateFindingAffectedRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UpdateFindingAffectedRequestFromJSON(json: any): UpdateFindingAffectedRequest {
    return UpdateFindingAffectedRequestFromJSONTyped(json, false);
}

export function UpdateFindingAffectedRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateFindingAffectedRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'screenshot': !exists(json, 'screenshot') ? undefined : json['screenshot'],
        'logFile': !exists(json, 'log_file') ? undefined : json['log_file'],
    };
}

export function UpdateFindingAffectedRequestToJSON(value?: UpdateFindingAffectedRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'screenshot': value.screenshot,
        'log_file': value.logFile,
    };
}

