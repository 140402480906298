/* tslint:disable */
/* eslint-disable */
/**
 * kraken
 * The core component of kraken-project
 *
 * The version of the OpenAPI document: 0.3.5
 * Contact: git@omikron.dev
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ManualInsert } from './ManualInsert';
import {
    ManualInsertFromJSON,
    ManualInsertFromJSONTyped,
    ManualInsertToJSON,
} from './ManualInsert';
import type { SourceAttack } from './SourceAttack';
import {
    SourceAttackFromJSON,
    SourceAttackFromJSONTyped,
    SourceAttackToJSON,
} from './SourceAttack';

/**
 * All data sources which contributed to an aggregated model
 * @export
 * @interface FullAggregationSource
 */
export interface FullAggregationSource {
    /**
     * All attack which contributed to an aggregated model
     * @type {Array<SourceAttack>}
     * @memberof FullAggregationSource
     */
    attacks: Array<SourceAttack>;
    /**
     * All manual inserts which contributed to an aggregated model
     * @type {Array<ManualInsert>}
     * @memberof FullAggregationSource
     */
    manualInsert: Array<ManualInsert>;
}

/**
 * Check if a given object implements the FullAggregationSource interface.
 */
export function instanceOfFullAggregationSource(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "attacks" in value;
    isInstance = isInstance && "manualInsert" in value;

    return isInstance;
}

export function FullAggregationSourceFromJSON(json: any): FullAggregationSource {
    return FullAggregationSourceFromJSONTyped(json, false);
}

export function FullAggregationSourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): FullAggregationSource {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'attacks': ((json['attacks'] as Array<any>).map(SourceAttackFromJSON)),
        'manualInsert': ((json['manual_insert'] as Array<any>).map(ManualInsertFromJSON)),
    };
}

export function FullAggregationSourceToJSON(value?: FullAggregationSource | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'attacks': ((value.attacks as Array<any>).map(SourceAttackToJSON)),
        'manual_insert': ((value.manualInsert as Array<any>).map(ManualInsertToJSON)),
    };
}

