/* tslint:disable */
/* eslint-disable */
/**
 * kraken
 * The core component of kraken-project
 *
 * The version of the OpenAPI document: 0.3.5
 * Contact: git@omikron.dev
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SimpleFinding } from './SimpleFinding';
import {
    SimpleFindingFromJSON,
    SimpleFindingFromJSONTyped,
    SimpleFindingToJSON,
} from './SimpleFinding';

/**
 * A list of simple findings
 * @export
 * @interface ListFindings
 */
export interface ListFindings {
    /**
     * The findings
     * @type {Array<SimpleFinding>}
     * @memberof ListFindings
     */
    findings: Array<SimpleFinding>;
}

/**
 * Check if a given object implements the ListFindings interface.
 */
export function instanceOfListFindings(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "findings" in value;

    return isInstance;
}

export function ListFindingsFromJSON(json: any): ListFindings {
    return ListFindingsFromJSONTyped(json, false);
}

export function ListFindingsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListFindings {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'findings': ((json['findings'] as Array<any>).map(SimpleFindingFromJSON)),
    };
}

export function ListFindingsToJSON(value?: ListFindings | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'findings': ((value.findings as Array<any>).map(SimpleFindingToJSON)),
    };
}

