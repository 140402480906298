import React from "react";

export default function AndroidIcon(props: React.HTMLAttributes<HTMLDivElement>) {
    return (
        <div className={"icon"} {...props}>
            <svg
                className={"neon"}
                width="800px"
                height="800px"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M18.06 9.33002C17.8107 9.33002 17.5716 9.42905 17.3953 9.60534C17.219 9.78162 17.12 10.0207 17.12 10.27V14.53C17.12 14.7793 17.219 15.0184 17.3953 15.1947C17.5716 15.371 17.8107 15.47 18.06 15.47C18.3093 15.47 18.5484 15.371 18.7247 15.1947C18.901 15.0184 19 14.7793 19 14.53V10.27C19 10.0207 18.901 9.78162 18.7247 9.60534C18.5484 9.42905 18.3093 9.33002 18.06 9.33002Z"
                    fill="#000000"
                />
                <path
                    d="M5.94 9.33002C5.6907 9.33002 5.4516 9.42905 5.27532 9.60534C5.09904 9.78162 5 10.0207 5 10.27V14.53C5 14.7793 5.09904 15.0184 5.27532 15.1947C5.4516 15.371 5.6907 15.47 5.94 15.47C6.1893 15.47 6.4284 15.371 6.60468 15.1947C6.78096 15.0184 6.88 14.7793 6.88 14.53V10.27C6.88 10.0207 6.78096 9.78162 6.60468 9.60534C6.4284 9.42905 6.1893 9.33002 5.94 9.33002Z"
                    fill="#000000"
                />
                <path
                    d="M7.56 9.33002V15.73C7.56 15.8705 7.58767 16.0097 7.64145 16.1395C7.69522 16.2693 7.77403 16.3873 7.87339 16.4866C7.97275 16.586 8.09071 16.6648 8.22053 16.7186C8.35034 16.7723 8.48948 16.8 8.63 16.8H9.31V19.07C9.31 19.3193 9.40903 19.5584 9.58532 19.7347C9.7616 19.911 10.0007 20.01 10.25 20.01C10.4993 20.01 10.7384 19.911 10.9147 19.7347C11.091 19.5584 11.19 19.3193 11.19 19.07V16.8H12.81V19.07C12.81 19.3193 12.909 19.5584 13.0853 19.7347C13.2616 19.911 13.5007 20.01 13.75 20.01C13.9993 20.01 14.2384 19.911 14.4147 19.7347C14.591 19.5584 14.69 19.3193 14.69 19.07V16.8H15.37C15.6538 16.8 15.9259 16.6873 16.1266 16.4866C16.3273 16.286 16.44 16.0138 16.44 15.73V9.33002H7.56Z"
                    fill="#000000"
                />
                <path
                    d="M16.32 8.00002C16.0257 7.01263 15.373 6.17089 14.49 5.64002L14.34 5.55002L14.18 5.47002L14.36 5.16002L14.89 4.16002C14.9 4.13153 14.9006 4.10055 14.8916 4.07171C14.8826 4.04288 14.8645 4.01773 14.84 4.00002H14.77C14.7445 4.00117 14.7195 4.00805 14.697 4.02017C14.6745 4.03229 14.655 4.04933 14.64 4.07002L14.1 5.00002L13.93 5.31002L13.77 5.24002L13.6 5.18002C12.5637 4.82036 11.4363 4.82036 10.4 5.18002L10.24 5.24002L10.07 5.31002L9.9 5.00002L9.36 4.00002C9.3508 3.98361 9.33847 3.96916 9.32369 3.95752C9.30892 3.94587 9.292 3.93724 9.27389 3.93214C9.25578 3.92703 9.23684 3.92554 9.21816 3.92775C9.19948 3.92996 9.18141 3.93583 9.165 3.94502C9.14858 3.95422 9.13414 3.96655 9.12249 3.98133C9.11084 3.9961 9.10222 4.01303 9.09711 4.03113C9.09201 4.04924 9.09051 4.06818 9.09273 4.08686C9.09494 4.10554 9.1008 4.12361 9.11 4.14002L9.64 5.14002L9.82 5.45002L9.66 5.53002L9.51 5.62002C8.62277 6.15307 7.97197 7.00413 7.69 8.00002C7.61195 8.25988 7.56825 8.52883 7.56 8.80002H16.44C16.4302 8.5297 16.3899 8.26133 16.32 8.00002ZM10 7.47002C9.92089 7.47002 9.84355 7.44656 9.77777 7.40261C9.71199 7.35866 9.66072 7.29619 9.63045 7.2231C9.60017 7.15001 9.59225 7.06958 9.60768 6.99199C9.62312 6.91439 9.66121 6.84312 9.71715 6.78718C9.7731 6.73124 9.84437 6.69314 9.92196 6.67771C9.99955 6.66228 10.08 6.6702 10.1531 6.70047C10.2262 6.73075 10.2886 6.78202 10.3326 6.8478C10.3765 6.91358 10.4 6.99091 10.4 7.07002C10.4 7.17611 10.3579 7.27785 10.2828 7.35287C10.2078 7.42788 10.1061 7.47002 10 7.47002ZM14 7.47002C13.9209 7.47002 13.8435 7.44656 13.7778 7.40261C13.712 7.35866 13.6607 7.29619 13.6304 7.2231C13.6002 7.15001 13.5922 7.06958 13.6077 6.99199C13.6231 6.91439 13.6612 6.84312 13.7172 6.78718C13.7731 6.73124 13.8444 6.69314 13.922 6.67771C13.9996 6.66228 14.08 6.6702 14.1531 6.70047C14.2262 6.73075 14.2886 6.78202 14.3326 6.8478C14.3765 6.91358 14.4 6.99091 14.4 7.07002C14.4 7.17611 14.3579 7.27785 14.2828 7.35287C14.2078 7.42788 14.1061 7.47002 14 7.47002Z"
                    fill="#000000"
                />
            </svg>
        </div>
    );
}
