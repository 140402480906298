/* tslint:disable */
/* eslint-disable */
/**
 * kraken
 * The core component of kraken-project
 *
 * The version of the OpenAPI document: 0.3.5
 * Contact: git@omikron.dev
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Color value
 * @export
 * @interface Color
 */
export interface Color {
    /**
     * Red value
     * @type {number}
     * @memberof Color
     */
    r: number;
    /**
     * Green value
     * @type {number}
     * @memberof Color
     */
    g: number;
    /**
     * Blue value
     * @type {number}
     * @memberof Color
     */
    b: number;
    /**
     * Alpha value
     * @type {number}
     * @memberof Color
     */
    a: number;
}

/**
 * Check if a given object implements the Color interface.
 */
export function instanceOfColor(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "r" in value;
    isInstance = isInstance && "g" in value;
    isInstance = isInstance && "b" in value;
    isInstance = isInstance && "a" in value;

    return isInstance;
}

export function ColorFromJSON(json: any): Color {
    return ColorFromJSONTyped(json, false);
}

export function ColorFromJSONTyped(json: any, ignoreDiscriminator: boolean): Color {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'r': json['r'],
        'g': json['g'],
        'b': json['b'],
        'a': json['a'],
    };
}

export function ColorToJSON(value?: Color | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'r': value.r,
        'g': value.g,
        'b': value.b,
        'a': value.a,
    };
}

