/* tslint:disable */
/* eslint-disable */
/**
 * kraken
 * The core component of kraken-project
 *
 * The version of the OpenAPI document: 0.3.5
 * Contact: git@omikron.dev
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiErrorResponse,
  CreateFindingDefinitionRequest,
  FullFindingDefinition,
  ListFindingDefinitionUsages,
  ListFindingDefinitions,
  UpdateFindingDefinitionRequest,
  UuidResponse,
} from '../models';
import {
    ApiErrorResponseFromJSON,
    ApiErrorResponseToJSON,
    CreateFindingDefinitionRequestFromJSON,
    CreateFindingDefinitionRequestToJSON,
    FullFindingDefinitionFromJSON,
    FullFindingDefinitionToJSON,
    ListFindingDefinitionUsagesFromJSON,
    ListFindingDefinitionUsagesToJSON,
    ListFindingDefinitionsFromJSON,
    ListFindingDefinitionsToJSON,
    UpdateFindingDefinitionRequestFromJSON,
    UpdateFindingDefinitionRequestToJSON,
    UuidResponseFromJSON,
    UuidResponseToJSON,
} from '../models';

export interface CreateFindingDefinitionOperationRequest {
    createFindingDefinitionRequest: CreateFindingDefinitionRequest;
}

export interface DeleteFindingDefinitionRequest {
    uuid: string;
}

export interface GetFindingDefinitionRequest {
    uuid: string;
}

export interface GetFindingDefinitionUsageRequest {
    uuid: string;
}

export interface UpdateFindingDefinitionOperationRequest {
    uuid: string;
    updateFindingDefinitionRequest: UpdateFindingDefinitionRequest;
}

/**
 * 
 */
export class KnowledgeBaseApi extends runtime.BaseAPI {

    /**
     * These definition serve as reference and knowledge base in kraken. They can be used to create a finding that references a definition and links it to one or multiple aggregations.
     * Add a definition for a finding
     */
    async createFindingDefinitionRaw(requestParameters: CreateFindingDefinitionOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UuidResponse>> {
        if (requestParameters.createFindingDefinitionRequest === null || requestParameters.createFindingDefinitionRequest === undefined) {
            throw new runtime.RequiredError('createFindingDefinitionRequest','Required parameter requestParameters.createFindingDefinitionRequest was null or undefined when calling createFindingDefinition.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/findingDefinitions`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateFindingDefinitionRequestToJSON(requestParameters.createFindingDefinitionRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UuidResponseFromJSON(jsonValue));
    }

    /**
     * These definition serve as reference and knowledge base in kraken. They can be used to create a finding that references a definition and links it to one or multiple aggregations.
     * Add a definition for a finding
     */
    async createFindingDefinition(requestParameters: CreateFindingDefinitionOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UuidResponse> {
        const response = await this.createFindingDefinitionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete a finding definition
     */
    async deleteFindingDefinitionRaw(requestParameters: DeleteFindingDefinitionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling deleteFindingDefinition.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/admin/findingDefinitions/{uuid}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a finding definition
     */
    async deleteFindingDefinition(requestParameters: DeleteFindingDefinitionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteFindingDefinitionRaw(requestParameters, initOverrides);
    }

    /**
     * Retrieve all finding definitions
     */
    async getAllFindingDefinitionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListFindingDefinitions>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/findingDefinitions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListFindingDefinitionsFromJSON(jsonValue));
    }

    /**
     * Retrieve all finding definitions
     */
    async getAllFindingDefinitions(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListFindingDefinitions> {
        const response = await this.getAllFindingDefinitionsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Retrieve a specific finding definition
     */
    async getFindingDefinitionRaw(requestParameters: GetFindingDefinitionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FullFindingDefinition>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling getFindingDefinition.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/findingDefinitions/{uuid}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FullFindingDefinitionFromJSON(jsonValue));
    }

    /**
     * Retrieve a specific finding definition
     */
    async getFindingDefinition(requestParameters: GetFindingDefinitionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FullFindingDefinition> {
        const response = await this.getFindingDefinitionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all findings using the finding definition
     */
    async getFindingDefinitionUsageRaw(requestParameters: GetFindingDefinitionUsageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListFindingDefinitionUsages>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling getFindingDefinitionUsage.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/admin/findingDefinitions/{uuid}/usages`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListFindingDefinitionUsagesFromJSON(jsonValue));
    }

    /**
     * Get all findings using the finding definition
     */
    async getFindingDefinitionUsage(requestParameters: GetFindingDefinitionUsageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListFindingDefinitionUsages> {
        const response = await this.getFindingDefinitionUsageRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * This endpoint only allows updating the `name`, `severity` and `cve`. The other values have to be updated through the websocket as part of a live editor.
     * Update a finding definition
     */
    async updateFindingDefinitionRaw(requestParameters: UpdateFindingDefinitionOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling updateFindingDefinition.');
        }

        if (requestParameters.updateFindingDefinitionRequest === null || requestParameters.updateFindingDefinitionRequest === undefined) {
            throw new runtime.RequiredError('updateFindingDefinitionRequest','Required parameter requestParameters.updateFindingDefinitionRequest was null or undefined when calling updateFindingDefinition.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/findingDefinitions/{uuid}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateFindingDefinitionRequestToJSON(requestParameters.updateFindingDefinitionRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * This endpoint only allows updating the `name`, `severity` and `cve`. The other values have to be updated through the websocket as part of a live editor.
     * Update a finding definition
     */
    async updateFindingDefinition(requestParameters: UpdateFindingDefinitionOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateFindingDefinitionRaw(requestParameters, initOverrides);
    }

}
