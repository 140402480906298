/* tslint:disable */
/* eslint-disable */
/**
 * kraken
 * The core component of kraken-project
 *
 * The version of the OpenAPI document: 0.3.5
 * Contact: git@omikron.dev
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiErrorResponse,
  CreateFindingCategoryRequest,
  ListFindingCategories,
  UpdateFindingCategoryRequest,
  UuidResponse,
} from '../models';
import {
    ApiErrorResponseFromJSON,
    ApiErrorResponseToJSON,
    CreateFindingCategoryRequestFromJSON,
    CreateFindingCategoryRequestToJSON,
    ListFindingCategoriesFromJSON,
    ListFindingCategoriesToJSON,
    UpdateFindingCategoryRequestFromJSON,
    UpdateFindingCategoryRequestToJSON,
    UuidResponseFromJSON,
    UuidResponseToJSON,
} from '../models';

export interface CreateFindingCategoryOperationRequest {
    createFindingCategoryRequest: CreateFindingCategoryRequest;
}

export interface DeleteFindingCategoryRequest {
    uuid: string;
}

export interface UpdateFindingCategoryOperationRequest {
    uuid: string;
    updateFindingCategoryRequest: UpdateFindingCategoryRequest;
}

/**
 * 
 */
export class FindingCategoriesApi extends runtime.BaseAPI {

    /**
     * This action requires admin privileges.
     * Create a finding category.
     */
    async createFindingCategoryRaw(requestParameters: CreateFindingCategoryOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UuidResponse>> {
        if (requestParameters.createFindingCategoryRequest === null || requestParameters.createFindingCategoryRequest === undefined) {
            throw new runtime.RequiredError('createFindingCategoryRequest','Required parameter requestParameters.createFindingCategoryRequest was null or undefined when calling createFindingCategory.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/admin/findingCategories`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateFindingCategoryRequestToJSON(requestParameters.createFindingCategoryRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UuidResponseFromJSON(jsonValue));
    }

    /**
     * This action requires admin privileges.
     * Create a finding category.
     */
    async createFindingCategory(requestParameters: CreateFindingCategoryOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UuidResponse> {
        const response = await this.createFindingCategoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Requires admin privileges.
     * Delete a finding category
     */
    async deleteFindingCategoryRaw(requestParameters: DeleteFindingCategoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling deleteFindingCategory.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/admin/findingCategories/{uuid}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Requires admin privileges.
     * Delete a finding category
     */
    async deleteFindingCategory(requestParameters: DeleteFindingCategoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteFindingCategoryRaw(requestParameters, initOverrides);
    }

    /**
     * Retrieve all finding categories
     */
    async getAllFindingCategoriesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListFindingCategories>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/findingCategories`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListFindingCategoriesFromJSON(jsonValue));
    }

    /**
     * Retrieve all finding categories
     */
    async getAllFindingCategories(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListFindingCategories> {
        const response = await this.getAllFindingCategoriesRaw(initOverrides);
        return await response.value();
    }

    /**
     * One of the options must be set  Requires admin privileges.
     * Update a finding category
     */
    async updateFindingCategoryRaw(requestParameters: UpdateFindingCategoryOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling updateFindingCategory.');
        }

        if (requestParameters.updateFindingCategoryRequest === null || requestParameters.updateFindingCategoryRequest === undefined) {
            throw new runtime.RequiredError('updateFindingCategoryRequest','Required parameter requestParameters.updateFindingCategoryRequest was null or undefined when calling updateFindingCategory.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/admin/findingCategories/{uuid}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateFindingCategoryRequestToJSON(requestParameters.updateFindingCategoryRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * One of the options must be set  Requires admin privileges.
     * Update a finding category
     */
    async updateFindingCategory(requestParameters: UpdateFindingCategoryOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateFindingCategoryRaw(requestParameters, initOverrides);
    }

}
