/* tslint:disable */
/* eslint-disable */
/**
 * kraken
 * The core component of kraken-project
 *
 * The version of the OpenAPI document: 0.3.5
 * Contact: git@omikron.dev
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Color } from './Color';
import {
    ColorFromJSON,
    ColorFromJSONTyped,
    ColorToJSON,
} from './Color';

/**
 * The request to update a finding category
 * @export
 * @interface UpdateFindingCategoryRequest
 */
export interface UpdateFindingCategoryRequest {
    /**
     * The category's name
     * @type {string}
     * @memberof UpdateFindingCategoryRequest
     */
    name?: string | null;
    /**
     * 
     * @type {Color}
     * @memberof UpdateFindingCategoryRequest
     */
    color?: Color | null;
}

/**
 * Check if a given object implements the UpdateFindingCategoryRequest interface.
 */
export function instanceOfUpdateFindingCategoryRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UpdateFindingCategoryRequestFromJSON(json: any): UpdateFindingCategoryRequest {
    return UpdateFindingCategoryRequestFromJSONTyped(json, false);
}

export function UpdateFindingCategoryRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateFindingCategoryRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'color': !exists(json, 'color') ? undefined : ColorFromJSON(json['color']),
    };
}

export function UpdateFindingCategoryRequestToJSON(value?: UpdateFindingCategoryRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'color': ColorToJSON(value.color),
    };
}

