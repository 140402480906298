/* tslint:disable */
/* eslint-disable */
/**
 * kraken
 * The core component of kraken-project
 *
 * The version of the OpenAPI document: 0.3.5
 * Contact: git@omikron.dev
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * The settings to configure a certificate transparency request
 * @export
 * @interface QueryCertificateTransparencyRequest
 */
export interface QueryCertificateTransparencyRequest {
    /**
     * Domain to query certificates for
     * @type {string}
     * @memberof QueryCertificateTransparencyRequest
     */
    target: string;
    /**
     * Should expired certificates be included as well
     * @type {boolean}
     * @memberof QueryCertificateTransparencyRequest
     */
    includeExpired: boolean;
    /**
     * The number of times the query should be retried if it failed.
     * @type {number}
     * @memberof QueryCertificateTransparencyRequest
     */
    maxRetries: number;
    /**
     * The interval that should be waited between retries.
     * 
     * The interval is specified in milliseconds.
     * @type {number}
     * @memberof QueryCertificateTransparencyRequest
     */
    retryInterval: number;
    /**
     * The workspace to execute the attack in
     * @type {string}
     * @memberof QueryCertificateTransparencyRequest
     */
    workspaceUuid: string;
}

/**
 * Check if a given object implements the QueryCertificateTransparencyRequest interface.
 */
export function instanceOfQueryCertificateTransparencyRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "target" in value;
    isInstance = isInstance && "includeExpired" in value;
    isInstance = isInstance && "maxRetries" in value;
    isInstance = isInstance && "retryInterval" in value;
    isInstance = isInstance && "workspaceUuid" in value;

    return isInstance;
}

export function QueryCertificateTransparencyRequestFromJSON(json: any): QueryCertificateTransparencyRequest {
    return QueryCertificateTransparencyRequestFromJSONTyped(json, false);
}

export function QueryCertificateTransparencyRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): QueryCertificateTransparencyRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'target': json['target'],
        'includeExpired': json['include_expired'],
        'maxRetries': json['max_retries'],
        'retryInterval': json['retry_interval'],
        'workspaceUuid': json['workspace_uuid'],
    };
}

export function QueryCertificateTransparencyRequestToJSON(value?: QueryCertificateTransparencyRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'target': value.target,
        'include_expired': value.includeExpired,
        'max_retries': value.maxRetries,
        'retry_interval': value.retryInterval,
        'workspace_uuid': value.workspaceUuid,
    };
}

