/* tslint:disable */
/* eslint-disable */
/**
 * kraken
 * The core component of kraken-project
 *
 * The version of the OpenAPI document: 0.3.5
 * Contact: git@omikron.dev
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SimpleLeech } from './SimpleLeech';
import {
    SimpleLeechFromJSON,
    SimpleLeechFromJSONTyped,
    SimpleLeechToJSON,
} from './SimpleLeech';

/**
 * The response that hold all leeches
 * @export
 * @interface ListLeeches
 */
export interface ListLeeches {
    /**
     * The list of leeches
     * @type {Array<SimpleLeech>}
     * @memberof ListLeeches
     */
    leeches: Array<SimpleLeech>;
}

/**
 * Check if a given object implements the ListLeeches interface.
 */
export function instanceOfListLeeches(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "leeches" in value;

    return isInstance;
}

export function ListLeechesFromJSON(json: any): ListLeeches {
    return ListLeechesFromJSONTyped(json, false);
}

export function ListLeechesFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListLeeches {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'leeches': ((json['leeches'] as Array<any>).map(SimpleLeechFromJSON)),
    };
}

export function ListLeechesToJSON(value?: ListLeeches | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'leeches': ((value.leeches as Array<any>).map(SimpleLeechToJSON)),
    };
}

