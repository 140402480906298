/* tslint:disable */
/* eslint-disable */
/**
 * kraken
 * The core component of kraken-project
 *
 * The version of the OpenAPI document: 0.3.5
 * Contact: git@omikron.dev
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiErrorResponse,
  OpenRequestInfo,
} from '../models';
import {
    ApiErrorResponseFromJSON,
    ApiErrorResponseToJSON,
    OpenRequestInfoFromJSON,
    OpenRequestInfoToJSON,
} from '../models';

export interface AcceptRequest {
    uuid: string;
    remember?: boolean;
}

export interface DenyRequest {
    uuid: string;
    remember?: boolean;
}

export interface InfoRequest {
    uuid: string;
}

/**
 * 
 */
export class OAuthApi extends runtime.BaseAPI {

    /**
     * Endpoint visited by user to grant a requesting application access
     */
    async acceptRaw(requestParameters: AcceptRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling accept.');
        }

        const queryParameters: any = {};

        if (requestParameters.remember !== undefined) {
            queryParameters['remember'] = requestParameters.remember;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/oauth/accept/{uuid}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Endpoint visited by user to grant a requesting application access
     */
    async accept(requestParameters: AcceptRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.acceptRaw(requestParameters, initOverrides);
    }

    /**
     * Endpoint visited by user to deny a requesting application access
     */
    async denyRaw(requestParameters: DenyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling deny.');
        }

        const queryParameters: any = {};

        if (requestParameters.remember !== undefined) {
            queryParameters['remember'] = requestParameters.remember;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/oauth/deny/{uuid}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Endpoint visited by user to deny a requesting application access
     */
    async deny(requestParameters: DenyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.denyRaw(requestParameters, initOverrides);
    }

    /**
     * Queried by the frontend to display information about the oauth request to the user
     */
    async infoRaw(requestParameters: InfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OpenRequestInfo>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling info.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/oauth/info/{uuid}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OpenRequestInfoFromJSON(jsonValue));
    }

    /**
     * Queried by the frontend to display information about the oauth request to the user
     */
    async info(requestParameters: InfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OpenRequestInfo> {
        const response = await this.infoRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
