/* tslint:disable */
/* eslint-disable */
/**
 * kraken
 * The core component of kraken-project
 *
 * The version of the OpenAPI document: 0.3.5
 * Contact: git@omikron.dev
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UserPermission } from './UserPermission';
import {
    UserPermissionFromJSON,
    UserPermissionFromJSONTyped,
    UserPermissionToJSON,
} from './UserPermission';

/**
 * The request to create a user
 * @export
 * @interface CreateUserRequest
 */
export interface CreateUserRequest {
    /**
     * The username
     * @type {string}
     * @memberof CreateUserRequest
     */
    username: string;
    /**
     * The displayname
     * @type {string}
     * @memberof CreateUserRequest
     */
    displayName: string;
    /**
     * The password that should be set
     * @type {string}
     * @memberof CreateUserRequest
     */
    password: string;
    /**
     * 
     * @type {UserPermission}
     * @memberof CreateUserRequest
     */
    permission: UserPermission;
}

/**
 * Check if a given object implements the CreateUserRequest interface.
 */
export function instanceOfCreateUserRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "username" in value;
    isInstance = isInstance && "displayName" in value;
    isInstance = isInstance && "password" in value;
    isInstance = isInstance && "permission" in value;

    return isInstance;
}

export function CreateUserRequestFromJSON(json: any): CreateUserRequest {
    return CreateUserRequestFromJSONTyped(json, false);
}

export function CreateUserRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUserRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'username': json['username'],
        'displayName': json['display_name'],
        'password': json['password'],
        'permission': UserPermissionFromJSON(json['permission']),
    };
}

export function CreateUserRequestToJSON(value?: CreateUserRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'username': value.username,
        'display_name': value.displayName,
        'password': value.password,
        'permission': UserPermissionToJSON(value.permission),
    };
}

