/* tslint:disable */
/* eslint-disable */
/**
 * kraken
 * The core component of kraken-project
 *
 * The version of the OpenAPI document: 0.3.5
 * Contact: git@omikron.dev
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ManualServiceCertainty } from './ManualServiceCertainty';
import {
    ManualServiceCertaintyFromJSON,
    ManualServiceCertaintyFromJSONTyped,
    ManualServiceCertaintyToJSON,
} from './ManualServiceCertainty';
import type { ServiceProtocols } from './ServiceProtocols';
import {
    ServiceProtocolsFromJSON,
    ServiceProtocolsFromJSONTyped,
    ServiceProtocolsToJSON,
} from './ServiceProtocols';

/**
 * The request to manually add a service
 * @export
 * @interface CreateServiceRequest
 */
export interface CreateServiceRequest {
    /**
     * The service's name
     * @type {string}
     * @memberof CreateServiceRequest
     */
    name: string;
    /**
     * 
     * @type {ManualServiceCertainty}
     * @memberof CreateServiceRequest
     */
    certainty: ManualServiceCertainty;
    /**
     * The ip address the service runs on
     * @type {string}
     * @memberof CreateServiceRequest
     */
    host: string;
    /**
     * An optional port the service runs on
     * 
     * If set, you must specify protocol
     * @type {number}
     * @memberof CreateServiceRequest
     */
    port?: number | null;
    /**
     * 
     * @type {ServiceProtocols}
     * @memberof CreateServiceRequest
     */
    protocols?: ServiceProtocols | null;
}

/**
 * Check if a given object implements the CreateServiceRequest interface.
 */
export function instanceOfCreateServiceRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "certainty" in value;
    isInstance = isInstance && "host" in value;

    return isInstance;
}

export function CreateServiceRequestFromJSON(json: any): CreateServiceRequest {
    return CreateServiceRequestFromJSONTyped(json, false);
}

export function CreateServiceRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateServiceRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'certainty': ManualServiceCertaintyFromJSON(json['certainty']),
        'host': json['host'],
        'port': !exists(json, 'port') ? undefined : json['port'],
        'protocols': !exists(json, 'protocols') ? undefined : ServiceProtocolsFromJSON(json['protocols']),
    };
}

export function CreateServiceRequestToJSON(value?: CreateServiceRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'certainty': ManualServiceCertaintyToJSON(value.certainty),
        'host': value.host,
        'port': value.port,
        'protocols': ServiceProtocolsToJSON(value.protocols),
    };
}

