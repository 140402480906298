/* tslint:disable */
/* eslint-disable */
/**
 * kraken
 * The core component of kraken-project
 *
 * The version of the OpenAPI document: 0.3.5
 * Contact: git@omikron.dev
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * The severity of a finding
 * @export
 */
export const FindingSeverity = {
    Okay: 'Okay',
    Low: 'Low',
    Medium: 'Medium',
    High: 'High',
    Critical: 'Critical'
} as const;
export type FindingSeverity = typeof FindingSeverity[keyof typeof FindingSeverity];


export function FindingSeverityFromJSON(json: any): FindingSeverity {
    return FindingSeverityFromJSONTyped(json, false);
}

export function FindingSeverityFromJSONTyped(json: any, ignoreDiscriminator: boolean): FindingSeverity {
    return json as FindingSeverity;
}

export function FindingSeverityToJSON(value?: FindingSeverity | null): any {
    return value as any;
}

