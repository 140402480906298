/* tslint:disable */
/* eslint-disable */
/**
 * kraken
 * The core component of kraken-project
 *
 * The version of the OpenAPI document: 0.3.5
 * Contact: git@omikron.dev
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { HostCertainty } from './HostCertainty';
import {
    HostCertaintyFromJSON,
    HostCertaintyFromJSONTyped,
    HostCertaintyToJSON,
} from './HostCertainty';
import type { OsType } from './OsType';
import {
    OsTypeFromJSON,
    OsTypeFromJSONTyped,
    OsTypeToJSON,
} from './OsType';

/**
 * The simple representation of a host
 * @export
 * @interface SimpleHost
 */
export interface SimpleHost {
    /**
     * The primary key of the host
     * @type {string}
     * @memberof SimpleHost
     */
    uuid: string;
    /**
     * The ip address of the host
     * @type {string}
     * @memberof SimpleHost
     */
    ipAddr: string;
    /**
     * 
     * @type {OsType}
     * @memberof SimpleHost
     */
    osType: OsType;
    /**
     * Response time in ms
     * @type {number}
     * @memberof SimpleHost
     */
    responseTime?: number | null;
    /**
     * A comment
     * @type {string}
     * @memberof SimpleHost
     */
    comment: string;
    /**
     * The workspace this host is in
     * @type {string}
     * @memberof SimpleHost
     */
    workspace: string;
    /**
     * The point in time, the record was created
     * @type {Date}
     * @memberof SimpleHost
     */
    createdAt: Date;
    /**
     * 
     * @type {HostCertainty}
     * @memberof SimpleHost
     */
    certainty: HostCertainty;
}

/**
 * Check if a given object implements the SimpleHost interface.
 */
export function instanceOfSimpleHost(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "uuid" in value;
    isInstance = isInstance && "ipAddr" in value;
    isInstance = isInstance && "osType" in value;
    isInstance = isInstance && "comment" in value;
    isInstance = isInstance && "workspace" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "certainty" in value;

    return isInstance;
}

export function SimpleHostFromJSON(json: any): SimpleHost {
    return SimpleHostFromJSONTyped(json, false);
}

export function SimpleHostFromJSONTyped(json: any, ignoreDiscriminator: boolean): SimpleHost {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'uuid': json['uuid'],
        'ipAddr': json['ip_addr'],
        'osType': OsTypeFromJSON(json['os_type']),
        'responseTime': !exists(json, 'response_time') ? undefined : json['response_time'],
        'comment': json['comment'],
        'workspace': json['workspace'],
        'createdAt': (new Date(json['created_at'])),
        'certainty': HostCertaintyFromJSON(json['certainty']),
    };
}

export function SimpleHostToJSON(value?: SimpleHost | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'uuid': value.uuid,
        'ip_addr': value.ipAddr,
        'os_type': OsTypeToJSON(value.osType),
        'response_time': value.responseTime,
        'comment': value.comment,
        'workspace': value.workspace,
        'created_at': (value.createdAt.toISOString()),
        'certainty': HostCertaintyToJSON(value.certainty),
    };
}

