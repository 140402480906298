import React from "react";

export default function KnowledgeIcon(props: React.HTMLAttributes<HTMLDivElement>) {
    return (
        <div className={"icon"} {...props}>
            <svg
                className={"neon"}
                fill="#000000"
                width="800px"
                height="800px"
                viewBox="0 0 33.867 33.867"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g>
                    <path d="m13.35 6.3913c-2.6523-0.045075-9.249 4.3084-10.445 4.3041-1.1964-0.0043-1.5813 0.02842-1.5813 0.02842l0.019637 1.3803 2.9905 3.39-0.5519-0.12868-2.3776-2.2784 0.05116 0.90796 5.1599 6.734s3.1871-0.65375 4.1047-1.1317c0.91758-0.47796 2.6838-1.6679 3.5088-1.7038 0.82499-0.03583 1.5402 0.3027 1.5803 0.90744 0.68498 0.01837 1.1141 0.01912 1.1141 0.01912s0.42916-7.4e-4 1.1141-0.01912c0.04008-0.60474 0.75527-0.94327 1.5803-0.90744s2.5913 1.2258 3.5088 1.7038c0.91758 0.47796 4.1047 1.1317 4.1047 1.1317l5.1599-6.734 0.09663-1.5141-1.5038 1.5932-0.5519 0.12867 2.1006-2.6701-0.0098-0.80874s-0.38491-0.03272-1.5813-0.02842-7.793-4.3492-10.445-4.3041-3.253 1.5208-3.253 1.5208 0.01824 7.7837 0.01964 8.4041c0.0014 0.6204-0.33951 0.56327-0.33951 0.56327s-0.34093 0.05713-0.33951-0.56327c0.0014-0.6204 0.01964-8.4041 0.01964-8.4041s-0.60069-1.4758-3.253-1.5208zm19.665 8.0341-5.5562 7.2244-7.3386-1.7343 0.03772 1.3426 7.3262 1.6118 5.7025-6.9722zm-32.164 0.0041-0.17157 1.4717 5.703 6.9727 7.3257-1.6123 0.03772-1.3426-7.3386 1.7343zm16.072 5.0581-2.3027 0.05581-0.6258 7.7758 2.9285-2.2758 2.9285 2.2758-0.6258-7.7758z" />
                </g>
            </svg>
        </div>
    );
}
