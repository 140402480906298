/* tslint:disable */
/* eslint-disable */
/**
 * kraken
 * The core component of kraken-project
 *
 * The version of the OpenAPI document: 0.3.5
 * Contact: git@omikron.dev
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FullServiceDetectionResult } from './FullServiceDetectionResult';
import {
    FullServiceDetectionResultFromJSON,
    FullServiceDetectionResultFromJSONTyped,
    FullServiceDetectionResultToJSON,
} from './FullServiceDetectionResult';

/**
 * 
 * @export
 * @interface SourceAttackResultOneOf4
 */
export interface SourceAttackResultOneOf4 {
    /**
     * 
     * @type {string}
     * @memberof SourceAttackResultOneOf4
     */
    attackType: SourceAttackResultOneOf4AttackTypeEnum;
    /**
     * The [`AttackType::ServiceDetection`] and its results
     * @type {Array<FullServiceDetectionResult>}
     * @memberof SourceAttackResultOneOf4
     */
    results: Array<FullServiceDetectionResult>;
}


/**
 * @export
 */
export const SourceAttackResultOneOf4AttackTypeEnum = {
    ServiceDetection: 'ServiceDetection'
} as const;
export type SourceAttackResultOneOf4AttackTypeEnum = typeof SourceAttackResultOneOf4AttackTypeEnum[keyof typeof SourceAttackResultOneOf4AttackTypeEnum];


/**
 * Check if a given object implements the SourceAttackResultOneOf4 interface.
 */
export function instanceOfSourceAttackResultOneOf4(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "attackType" in value;
    isInstance = isInstance && "results" in value;

    return isInstance;
}

export function SourceAttackResultOneOf4FromJSON(json: any): SourceAttackResultOneOf4 {
    return SourceAttackResultOneOf4FromJSONTyped(json, false);
}

export function SourceAttackResultOneOf4FromJSONTyped(json: any, ignoreDiscriminator: boolean): SourceAttackResultOneOf4 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'attackType': json['attack_type'],
        'results': ((json['results'] as Array<any>).map(FullServiceDetectionResultFromJSON)),
    };
}

export function SourceAttackResultOneOf4ToJSON(value?: SourceAttackResultOneOf4 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'attack_type': value.attackType,
        'results': ((value.results as Array<any>).map(FullServiceDetectionResultToJSON)),
    };
}

