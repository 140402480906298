import React from "react";

export default function AttackIcon(props: React.HTMLAttributes<HTMLDivElement>) {
    return (
        <div className={"icon"} {...props}>
            <svg
                className={"neon"}
                fill="#000000"
                width="800px"
                height="800px"
                viewBox="0 0 128 128"
                enableBackground="new 0 0 128 128"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path d="m96.1 10.774c-29.465-17.659-67.667-8.089-85.326 21.376s-8.089 67.667 21.376 85.326 67.666 8.089 85.326-21.376 8.089-67.667-21.376-85.326zm-52.368 86.889c-3.327-1.994-4.407-6.307-2.413-9.634s6.307-4.407 9.634-2.413 4.407 6.307 2.413 9.634c-1.994 3.326-6.307 4.407-9.634 2.413zm4.046 20.318c0.52-1.799 0.184-4.284 0.184-4.284 4.128-1.522 3.281-5.636 3.281-5.636 3.985-0.633 3.869-4.504 3.869-4.504 8.189 4.226 22.771 7.215 27.474 7.987s7.225-1.323 7.225-1.323c2.179-1.521 3.381-5.966 1.216-6.094-16.311-0.964-30.198-6.695-33.198-14.049-1.901-4.66-1.526-13.39-1.526-13.39s-7.522 4.446-12.528 4.967c-7.9 0.821-19.5-8.723-28.041-22.654-1.134-1.849-4.487 1.307-4.801 3.945 0 0-0.658 3.212 2.24 6.996s12.409 15.235 19.996 20.464c0 0-3.469 1.722-2.148 5.535 0 0-4.028 1.192-3.424 5.55 0 0-2.383 0.887-3.719 2.21-17.858-17.9-22.019-46.268-8.429-68.944 13.724-22.898 41.103-32.569 65.49-24.849-3.791 7.819-20.455 35.647-22.783 39.236-2.55 3.93-9.987 14.092-13.914 18.367s-10.617 8.257-10.617 8.257c8.98 9.086 23.021-2.09 23.021-2.09l35.321-58.934c0.433 0.254 0.531 0.305 0.795 0.463 0.24 0.144 0.499 0.306 0.712 0.439l-35.323 58.934s-2.92 17.841 9.326 21.476c0 0 0.593-7.637 2.512-13.115s7.402-16.812 9.665-20.913c2.066-3.744 18.693-31.571 23.796-38.619 18.284 17.869 22.659 46.56 8.942 69.448-13.561 22.628-40.448 32.345-64.614 25.124z" />
            </svg>
        </div>
    );
}
