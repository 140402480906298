/* tslint:disable */
/* eslint-disable */
/**
 * kraken
 * The core component of kraken-project
 *
 * The version of the OpenAPI document: 0.3.5
 * Contact: git@omikron.dev
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SimpleDomain } from './SimpleDomain';
import {
    SimpleDomainFromJSON,
    SimpleDomainFromJSONTyped,
    SimpleDomainToJSON,
} from './SimpleDomain';

/**
 * 
 * @export
 * @interface SearchResultEntryOneOf3
 */
export interface SearchResultEntryOneOf3 {
    /**
     * 
     * @type {SimpleDomain}
     * @memberof SearchResultEntryOneOf3
     */
    domainEntry: SimpleDomain;
}

/**
 * Check if a given object implements the SearchResultEntryOneOf3 interface.
 */
export function instanceOfSearchResultEntryOneOf3(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "domainEntry" in value;

    return isInstance;
}

export function SearchResultEntryOneOf3FromJSON(json: any): SearchResultEntryOneOf3 {
    return SearchResultEntryOneOf3FromJSONTyped(json, false);
}

export function SearchResultEntryOneOf3FromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchResultEntryOneOf3 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'domainEntry': SimpleDomainFromJSON(json['DomainEntry']),
    };
}

export function SearchResultEntryOneOf3ToJSON(value?: SearchResultEntryOneOf3 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'DomainEntry': SimpleDomainToJSON(value.domainEntry),
    };
}

