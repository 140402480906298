import React from "react";

export default function FreeBSDIcon(props: React.HTMLAttributes<HTMLDivElement>) {
    return (
        <div className={"icon"} {...props}>
            <svg
                className={"neon"}
                fill="#000000"
                width="800px"
                height="800px"
                viewBox="0 0 32 32"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path d="M 5 5 L 5 6 C 5 7.851563 5.621094 9.855469 6.5 11.46875 C 5.554688 13.09375 5 14.988281 5 17 C 5 23.0625 9.9375 28 16 28 C 21.695313 28 26.386719 23.648438 26.9375 18.09375 C 26.972656 17.730469 27 17.371094 27 17 C 27 15.425781 26.664063 13.902344 26.03125 12.5 C 25.980469 12.585938 25.949219 12.648438 25.90625 12.71875 C 25.5625 13.484375 25.164063 14.292969 24.75 14.96875 C 24.90625 15.632813 25 16.300781 25 17 C 25 17.304688 24.996094 17.609375 24.96875 17.90625 C 24.515625 22.457031 20.679688 26 16 26 C 11.015625 26 7 21.984375 7 17 C 7 12.015625 11.015625 8 16 8 C 16.429688 8 16.835938 8.035156 17.25 8.09375 C 17.492188 7.769531 17.742188 7.480469 17.96875 7.21875 L 17.96875 7.1875 L 18.15625 7 C 18.375 6.761719 18.582031 6.558594 18.78125 6.375 C 17.878906 6.136719 16.953125 6 16 6 C 14.605469 6 13.261719 6.273438 12.03125 6.75 C 11.742188 6.546875 11.390625 6.320313 10.9375 6.09375 C 9.816406 5.535156 8.167969 5 6 5 Z M 26 5 C 23.832031 5 22.183594 5.535156 21.0625 6.09375 C 19.941406 6.652344 19.28125 7.28125 19.28125 7.28125 L 19 7.59375 L 19 8.03125 C 19 8.03125 19.023438 9.242188 19.65625 10.46875 C 20.289063 11.695313 21.71875 13 24 13 L 24.53125 13 L 24.84375 12.5625 C 24.84375 12.5625 27 9.445313 27 6 L 27 5 Z M 7.21875 7.15625 C 8.3125 7.296875 9.25 7.546875 9.90625 7.84375 C 9.160156 8.339844 8.507813 8.933594 7.90625 9.59375 C 7.609375 8.8125 7.355469 7.964844 7.21875 7.15625 Z M 24.71875 7.15625 C 24.390625 8.914063 23.808594 10.292969 23.46875 10.875 C 22.371094 10.707031 21.773438 10.1875 21.4375 9.53125 C 21.15625 8.988281 21.160156 8.722656 21.125 8.4375 C 21.296875 8.292969 21.355469 8.199219 21.9375 7.90625 C 22.582031 7.585938 23.574219 7.308594 24.71875 7.15625 Z" />
            </svg>
        </div>
    );
}
