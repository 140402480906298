type BookUserIconProps = {};

export default function BookUserIcon(props: BookUserIconProps) {
    return (
        <div className={"icon"} {...props}>
            <svg
                className="neon"
                fill="none"
                stroke="#000"
                strokeLinecap="round"
                strokeLinejoin="round"
                width="800px"
                height="800px"
                strokeWidth="32px"
                viewBox="0 0 512 512"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path d="M 256 160 c 16 -63.16 76.43 -95.41 208 -96 a 15.94 15.94 0 0 1 16 16 V 368 a 16 16 0 0 1 -16 16 C 390 384 352 393 336 398 M 32 366.07 V 80 A 15.94 15.94 0 0 1 48 64 C 179.57 64.59 240 96.84 256 160" />
                <line x1="256" y1="160" x2="256" y2="240" />
                <g transform="scale(.55 .55) translate(75 420)" fill="#000" strokeWidth="20">
                    <path d="M258.9,48C141.92,46.42,46.42,141.92,48,258.9,49.56,371.09,140.91,462.44,253.1,464c117,1.6,212.48-93.9,210.88-210.88C462.44,140.91,371.09,49.56,258.9,48ZM385.32,375.25a4,4,0,0,1-6.14-.32,124.27,124.27,0,0,0-32.35-29.59C321.37,329,289.11,320,256,320s-65.37,9-90.83,25.34a124.24,124.24,0,0,0-32.35,29.58,4,4,0,0,1-6.14.32A175.32,175.32,0,0,1,80,259C78.37,161.69,158.22,80.24,255.57,80S432,158.81,432,256A175.32,175.32,0,0,1,385.32,375.25Z" />
                    <path d="M256,144c-19.72,0-37.55,7.39-50.22,20.82s-19,32-17.57,51.93C191.11,256,221.52,288,256,288s64.83-32,67.79-71.24c1.48-19.74-4.8-38.14-17.68-51.82C293.39,151.44,275.59,144,256,144Z" />
                </g>
            </svg>
        </div>
    );
}
