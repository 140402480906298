/* tslint:disable */
/* eslint-disable */
/**
 * kraken
 * The core component of kraken-project
 *
 * The version of the OpenAPI document: 0.3.5
 * Contact: git@omikron.dev
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SearchTypeOneOf
 */
export interface SearchTypeOneOf {
    /**
     * Search for a simple pattern
     * @type {string}
     * @memberof SearchTypeOneOf
     */
    simple: string;
}

/**
 * Check if a given object implements the SearchTypeOneOf interface.
 */
export function instanceOfSearchTypeOneOf(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "simple" in value;

    return isInstance;
}

export function SearchTypeOneOfFromJSON(json: any): SearchTypeOneOf {
    return SearchTypeOneOfFromJSONTyped(json, false);
}

export function SearchTypeOneOfFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchTypeOneOf {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'simple': json['Simple'],
    };
}

export function SearchTypeOneOfToJSON(value?: SearchTypeOneOf | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Simple': value.simple,
    };
}

