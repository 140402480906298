/* tslint:disable */
/* eslint-disable */
/**
 * kraken
 * The core component of kraken-project
 *
 * The version of the OpenAPI document: 0.3.5
 * Contact: git@omikron.dev
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SimpleQueryUnhashedResult } from './SimpleQueryUnhashedResult';
import {
    SimpleQueryUnhashedResultFromJSON,
    SimpleQueryUnhashedResultFromJSONTyped,
    SimpleQueryUnhashedResultToJSON,
} from './SimpleQueryUnhashedResult';

/**
 * 
 * @export
 * @interface SearchResultEntryOneOf6
 */
export interface SearchResultEntryOneOf6 {
    /**
     * 
     * @type {SimpleQueryUnhashedResult}
     * @memberof SearchResultEntryOneOf6
     */
    dehashedQueryResultEntry: SimpleQueryUnhashedResult;
}

/**
 * Check if a given object implements the SearchResultEntryOneOf6 interface.
 */
export function instanceOfSearchResultEntryOneOf6(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "dehashedQueryResultEntry" in value;

    return isInstance;
}

export function SearchResultEntryOneOf6FromJSON(json: any): SearchResultEntryOneOf6 {
    return SearchResultEntryOneOf6FromJSONTyped(json, false);
}

export function SearchResultEntryOneOf6FromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchResultEntryOneOf6 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dehashedQueryResultEntry': SimpleQueryUnhashedResultFromJSON(json['DehashedQueryResultEntry']),
    };
}

export function SearchResultEntryOneOf6ToJSON(value?: SearchResultEntryOneOf6 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'DehashedQueryResultEntry': SimpleQueryUnhashedResultToJSON(value.dehashedQueryResultEntry),
    };
}

