/* tslint:disable */
/* eslint-disable */
/**
 * kraken
 * The core component of kraken-project
 *
 * The version of the OpenAPI document: 0.3.5
 * Contact: git@omikron.dev
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * The type of DNS TXT scan result for service hints
 * @export
 */
export const DnsTxtScanServiceHintType = {
    HasGoogleAccount: 'HasGoogleAccount',
    HasGlobalsignAccount: 'HasGlobalsignAccount',
    HasGlobalsignSMime: 'HasGlobalsignSMime',
    HasDocusignAccount: 'HasDocusignAccount',
    HasAppleAccount: 'HasAppleAccount',
    HasFacebookAccount: 'HasFacebookAccount',
    HasHubspotAccount: 'HasHubspotAccount',
    HasMsDynamics365: 'HasMSDynamics365',
    HasStripeAccount: 'HasStripeAccount',
    HasOneTrustSso: 'HasOneTrustSso',
    HasBrevoAccount: 'HasBrevoAccount',
    OwnsAtlassianAccounts: 'OwnsAtlassianAccounts',
    OwnsZoomAccounts: 'OwnsZoomAccounts',
    EmailProtonMail: 'EmailProtonMail'
} as const;
export type DnsTxtScanServiceHintType = typeof DnsTxtScanServiceHintType[keyof typeof DnsTxtScanServiceHintType];


export function DnsTxtScanServiceHintTypeFromJSON(json: any): DnsTxtScanServiceHintType {
    return DnsTxtScanServiceHintTypeFromJSONTyped(json, false);
}

export function DnsTxtScanServiceHintTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): DnsTxtScanServiceHintType {
    return json as DnsTxtScanServiceHintType;
}

export function DnsTxtScanServiceHintTypeToJSON(value?: DnsTxtScanServiceHintType | null): any {
    return value as any;
}

