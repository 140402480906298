/* tslint:disable */
/* eslint-disable */
/**
 * kraken
 * The core component of kraken-project
 *
 * The version of the OpenAPI document: 0.3.5
 * Contact: git@omikron.dev
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FullApiKey } from './FullApiKey';
import {
    FullApiKeyFromJSON,
    FullApiKeyFromJSONTyped,
    FullApiKeyToJSON,
} from './FullApiKey';

/**
 * The response that contains all api keys
 * @export
 * @interface ListApiKeys
 */
export interface ListApiKeys {
    /**
     * The list of api keys
     * @type {Array<FullApiKey>}
     * @memberof ListApiKeys
     */
    keys: Array<FullApiKey>;
}

/**
 * Check if a given object implements the ListApiKeys interface.
 */
export function instanceOfListApiKeys(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "keys" in value;

    return isInstance;
}

export function ListApiKeysFromJSON(json: any): ListApiKeys {
    return ListApiKeysFromJSONTyped(json, false);
}

export function ListApiKeysFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListApiKeys {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'keys': ((json['keys'] as Array<any>).map(FullApiKeyFromJSON)),
    };
}

export function ListApiKeysToJSON(value?: ListApiKeys | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'keys': ((value.keys as Array<any>).map(FullApiKeyToJSON)),
    };
}

