import React from "react";

export default function WorkspaceIcon(props: React.HTMLAttributes<HTMLDivElement>) {
    return (
        <div className={"icon"} {...props}>
            <svg
                className={"neon"}
                fill="#000000"
                width="800px"
                height="800px"
                viewBox="0 0 33.866668 33.866668"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g>
                    <path d="m16.954 1.6857c0 2e-7 -1.6378 2.5806-2.7792 4.6648 2.779-0.49626 5.5082-0.049609 5.5082-0.049609zm0 5.8064a9.4289 9.4289 0 0 0 -5.1356 1.5312l-3.2985-0.98909 1.093 2.9745a9.4289 9.4289 0 0 0 -2.0877 5.9123 9.4289 9.4289 0 0 0 1.9942 5.7805l-1.0036 3.3445 3.3083-1.2165a9.4289 9.4289 0 0 0 5.1299 1.5203 9.4289 9.4289 0 0 0 4.962 -1.4252l3.4205 1.0263-1.1147-3.0339a9.4289 9.4289 0 0 0 2.1611 -5.996 9.4289 9.4289 0 0 0 -2.0004 -5.7888l0.95808-3.1936-3.1424 1.1555a9.4289 9.4289 0 0 0 -5.2441 -1.602zm0 3.6261a6.0013 5.8772 0 0 1 6.0012 5.8772 6.0013 5.8772 0 0 1 -6.0012 5.8772 6.0013 5.8772 0 0 1 -6.0017 -5.8772 6.0013 5.8772 0 0 1 6.0017 -5.8772zm10.562 3.0567c0.49626 2.779 0.04961 5.5082 0.04961 5.5082l4.6152-2.7295c-3e-6 0-2.5806-1.6373-4.6648-2.7786zm-21.215 0.0088-4.6152 2.7295c1e-6 0 2.5806 1.6378 4.6648 2.7792-0.49626-2.7791-0.049609-5.5087-0.049609-5.5087zm13.391 13.333c-2.779 0.49626-5.5082 0.04961-5.5082 0.04961l2.729 4.6152c0-3e-6 1.6378-2.5806 2.7792-4.6648z" />
                </g>
            </svg>
        </div>
    );
}
