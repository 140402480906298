/* tslint:disable */
/* eslint-disable */
/**
 * kraken
 * The core component of kraken-project
 *
 * The version of the OpenAPI document: 0.3.5
 * Contact: git@omikron.dev
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FindingSection } from './FindingSection';
import {
    FindingSectionFromJSON,
    FindingSectionFromJSONTyped,
    FindingSectionToJSON,
} from './FindingSection';

/**
 * The editor for a [FindingDefinition]
 * @export
 * @interface EditorTargetOneOfFindingDefinition
 */
export interface EditorTargetOneOfFindingDefinition {
    /**
     * The finding definition that is active
     * @type {string}
     * @memberof EditorTargetOneOfFindingDefinition
     */
    findingDefinition: string;
    /**
     * 
     * @type {FindingSection}
     * @memberof EditorTargetOneOfFindingDefinition
     */
    findingSection: FindingSection;
}

/**
 * Check if a given object implements the EditorTargetOneOfFindingDefinition interface.
 */
export function instanceOfEditorTargetOneOfFindingDefinition(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "findingDefinition" in value;
    isInstance = isInstance && "findingSection" in value;

    return isInstance;
}

export function EditorTargetOneOfFindingDefinitionFromJSON(json: any): EditorTargetOneOfFindingDefinition {
    return EditorTargetOneOfFindingDefinitionFromJSONTyped(json, false);
}

export function EditorTargetOneOfFindingDefinitionFromJSONTyped(json: any, ignoreDiscriminator: boolean): EditorTargetOneOfFindingDefinition {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'findingDefinition': json['finding_definition'],
        'findingSection': FindingSectionFromJSON(json['finding_section']),
    };
}

export function EditorTargetOneOfFindingDefinitionToJSON(value?: EditorTargetOneOfFindingDefinition | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'finding_definition': value.findingDefinition,
        'finding_section': FindingSectionToJSON(value.findingSection),
    };
}

