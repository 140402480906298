/* tslint:disable */
/* eslint-disable */
/**
 * kraken
 * The core component of kraken-project
 *
 * The version of the OpenAPI document: 0.3.5
 * Contact: git@omikron.dev
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AggregationType } from './AggregationType';
import {
    AggregationTypeFromJSON,
    AggregationTypeFromJSONTyped,
    AggregationTypeToJSON,
} from './AggregationType';

/**
 * Workspace tags were updated on an aggregation
 * @export
 * @interface WsMessageOneOf26
 */
export interface WsMessageOneOf26 {
    /**
     * The workspace the aggregation is related to
     * @type {string}
     * @memberof WsMessageOneOf26
     */
    workspace: string;
    /**
     * 
     * @type {AggregationType}
     * @memberof WsMessageOneOf26
     */
    aggregation: AggregationType;
    /**
     * The uuid of the model
     * @type {string}
     * @memberof WsMessageOneOf26
     */
    uuid: string;
    /**
     * The updated list of tags
     * @type {Array<string>}
     * @memberof WsMessageOneOf26
     */
    tags: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof WsMessageOneOf26
     */
    type: WsMessageOneOf26TypeEnum;
}


/**
 * @export
 */
export const WsMessageOneOf26TypeEnum = {
    UpdatedWorkspaceTags: 'UpdatedWorkspaceTags'
} as const;
export type WsMessageOneOf26TypeEnum = typeof WsMessageOneOf26TypeEnum[keyof typeof WsMessageOneOf26TypeEnum];


/**
 * Check if a given object implements the WsMessageOneOf26 interface.
 */
export function instanceOfWsMessageOneOf26(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "workspace" in value;
    isInstance = isInstance && "aggregation" in value;
    isInstance = isInstance && "uuid" in value;
    isInstance = isInstance && "tags" in value;
    isInstance = isInstance && "type" in value;

    return isInstance;
}

export function WsMessageOneOf26FromJSON(json: any): WsMessageOneOf26 {
    return WsMessageOneOf26FromJSONTyped(json, false);
}

export function WsMessageOneOf26FromJSONTyped(json: any, ignoreDiscriminator: boolean): WsMessageOneOf26 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'workspace': json['workspace'],
        'aggregation': AggregationTypeFromJSON(json['aggregation']),
        'uuid': json['uuid'],
        'tags': json['tags'],
        'type': json['type'],
    };
}

export function WsMessageOneOf26ToJSON(value?: WsMessageOneOf26 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'workspace': value.workspace,
        'aggregation': AggregationTypeToJSON(value.aggregation),
        'uuid': value.uuid,
        'tags': value.tags,
        'type': value.type,
    };
}

