/* tslint:disable */
/* eslint-disable */
/**
 * kraken
 * The core component of kraken-project
 *
 * The version of the OpenAPI document: 0.3.5
 * Contact: git@omikron.dev
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FullGlobalTag } from './FullGlobalTag';
import {
    FullGlobalTagFromJSON,
    FullGlobalTagFromJSONTyped,
    FullGlobalTagToJSON,
} from './FullGlobalTag';

/**
 * The response to a request to retrieve all global tags
 * @export
 * @interface ListGlobalTags
 */
export interface ListGlobalTags {
    /**
     * List of global tags
     * @type {Array<FullGlobalTag>}
     * @memberof ListGlobalTags
     */
    globalTags: Array<FullGlobalTag>;
}

/**
 * Check if a given object implements the ListGlobalTags interface.
 */
export function instanceOfListGlobalTags(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "globalTags" in value;

    return isInstance;
}

export function ListGlobalTagsFromJSON(json: any): ListGlobalTags {
    return ListGlobalTagsFromJSONTyped(json, false);
}

export function ListGlobalTagsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListGlobalTags {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'globalTags': ((json['global_tags'] as Array<any>).map(FullGlobalTagFromJSON)),
    };
}

export function ListGlobalTagsToJSON(value?: ListGlobalTags | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'global_tags': ((value.globalTags as Array<any>).map(FullGlobalTagToJSON)),
    };
}

