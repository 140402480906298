/* tslint:disable */
/* eslint-disable */
/**
 * kraken
 * The core component of kraken-project
 *
 * The version of the OpenAPI document: 0.3.5
 * Contact: git@omikron.dev
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CertificateTransparencyEntry } from './CertificateTransparencyEntry';
import {
    CertificateTransparencyEntryFromJSON,
    CertificateTransparencyEntryFromJSONTyped,
    CertificateTransparencyEntryToJSON,
} from './CertificateTransparencyEntry';

/**
 * A result to a certificate transparency request
 * @export
 * @interface WsMessageOneOf9
 */
export interface WsMessageOneOf9 {
    /**
     * The corresponding id of the attack
     * @type {string}
     * @memberof WsMessageOneOf9
     */
    attackUuid: string;
    /**
     * The entries of the result
     * @type {Array<CertificateTransparencyEntry>}
     * @memberof WsMessageOneOf9
     */
    entries: Array<CertificateTransparencyEntry>;
    /**
     * 
     * @type {string}
     * @memberof WsMessageOneOf9
     */
    type: WsMessageOneOf9TypeEnum;
}


/**
 * @export
 */
export const WsMessageOneOf9TypeEnum = {
    CertificateTransparencyResult: 'CertificateTransparencyResult'
} as const;
export type WsMessageOneOf9TypeEnum = typeof WsMessageOneOf9TypeEnum[keyof typeof WsMessageOneOf9TypeEnum];


/**
 * Check if a given object implements the WsMessageOneOf9 interface.
 */
export function instanceOfWsMessageOneOf9(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "attackUuid" in value;
    isInstance = isInstance && "entries" in value;
    isInstance = isInstance && "type" in value;

    return isInstance;
}

export function WsMessageOneOf9FromJSON(json: any): WsMessageOneOf9 {
    return WsMessageOneOf9FromJSONTyped(json, false);
}

export function WsMessageOneOf9FromJSONTyped(json: any, ignoreDiscriminator: boolean): WsMessageOneOf9 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'attackUuid': json['attack_uuid'],
        'entries': ((json['entries'] as Array<any>).map(CertificateTransparencyEntryFromJSON)),
        'type': json['type'],
    };
}

export function WsMessageOneOf9ToJSON(value?: WsMessageOneOf9 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'attack_uuid': value.attackUuid,
        'entries': ((value.entries as Array<any>).map(CertificateTransparencyEntryToJSON)),
        'type': value.type,
    };
}

