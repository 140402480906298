/* tslint:disable */
/* eslint-disable */
/**
 * kraken
 * The core component of kraken-project
 *
 * The version of the OpenAPI document: 0.3.5
 * Contact: git@omikron.dev
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SimpleFindingDefinition } from './SimpleFindingDefinition';
import {
    SimpleFindingDefinitionFromJSON,
    SimpleFindingDefinitionFromJSONTyped,
    SimpleFindingDefinitionToJSON,
} from './SimpleFindingDefinition';

/**
 * A list of simple definition of a finding
 * @export
 * @interface ListFindingDefinitions
 */
export interface ListFindingDefinitions {
    /**
     * The finding definitions
     * @type {Array<SimpleFindingDefinition>}
     * @memberof ListFindingDefinitions
     */
    findingDefinitions: Array<SimpleFindingDefinition>;
}

/**
 * Check if a given object implements the ListFindingDefinitions interface.
 */
export function instanceOfListFindingDefinitions(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "findingDefinitions" in value;

    return isInstance;
}

export function ListFindingDefinitionsFromJSON(json: any): ListFindingDefinitions {
    return ListFindingDefinitionsFromJSONTyped(json, false);
}

export function ListFindingDefinitionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListFindingDefinitions {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'findingDefinitions': ((json['finding_definitions'] as Array<any>).map(SimpleFindingDefinitionFromJSON)),
    };
}

export function ListFindingDefinitionsToJSON(value?: ListFindingDefinitions | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'finding_definitions': ((value.findingDefinitions as Array<any>).map(SimpleFindingDefinitionToJSON)),
    };
}

