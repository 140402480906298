/* tslint:disable */
/* eslint-disable */
/**
 * kraken
 * The core component of kraken-project
 *
 * The version of the OpenAPI document: 0.3.5
 * Contact: git@omikron.dev
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SimpleAttack } from './SimpleAttack';
import {
    SimpleAttackFromJSON,
    SimpleAttackFromJSONTyped,
    SimpleAttackToJSON,
} from './SimpleAttack';

/**
 * A notification about a finished attack
 * @export
 * @interface WsMessageOneOf3
 */
export interface WsMessageOneOf3 {
    /**
     * 
     * @type {SimpleAttack}
     * @memberof WsMessageOneOf3
     */
    attack: SimpleAttack;
    /**
     * 
     * @type {string}
     * @memberof WsMessageOneOf3
     */
    type: WsMessageOneOf3TypeEnum;
}


/**
 * @export
 */
export const WsMessageOneOf3TypeEnum = {
    AttackFinished: 'AttackFinished'
} as const;
export type WsMessageOneOf3TypeEnum = typeof WsMessageOneOf3TypeEnum[keyof typeof WsMessageOneOf3TypeEnum];


/**
 * Check if a given object implements the WsMessageOneOf3 interface.
 */
export function instanceOfWsMessageOneOf3(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "attack" in value;
    isInstance = isInstance && "type" in value;

    return isInstance;
}

export function WsMessageOneOf3FromJSON(json: any): WsMessageOneOf3 {
    return WsMessageOneOf3FromJSONTyped(json, false);
}

export function WsMessageOneOf3FromJSONTyped(json: any, ignoreDiscriminator: boolean): WsMessageOneOf3 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'attack': SimpleAttackFromJSON(json['attack']),
        'type': json['type'],
    };
}

export function WsMessageOneOf3ToJSON(value?: WsMessageOneOf3 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'attack': SimpleAttackToJSON(value.attack),
        'type': value.type,
    };
}

