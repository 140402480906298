/* tslint:disable */
/* eslint-disable */
/**
 * kraken
 * The core component of kraken-project
 *
 * The version of the OpenAPI document: 0.3.5
 * Contact: git@omikron.dev
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * The request to create a new leech
 * @export
 * @interface CreateLeechRequest
 */
export interface CreateLeechRequest {
    /**
     * Name of the leech
     * @type {string}
     * @memberof CreateLeechRequest
     */
    name: string;
    /**
     * Address of the leech with schema
     * @type {string}
     * @memberof CreateLeechRequest
     */
    address: string;
    /**
     * Description of the leech
     * @type {string}
     * @memberof CreateLeechRequest
     */
    description?: string | null;
}

/**
 * Check if a given object implements the CreateLeechRequest interface.
 */
export function instanceOfCreateLeechRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "address" in value;

    return isInstance;
}

export function CreateLeechRequestFromJSON(json: any): CreateLeechRequest {
    return CreateLeechRequestFromJSONTyped(json, false);
}

export function CreateLeechRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateLeechRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'address': json['address'],
        'description': !exists(json, 'description') ? undefined : json['description'],
    };
}

export function CreateLeechRequestToJSON(value?: CreateLeechRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'address': value.address,
        'description': value.description,
    };
}

