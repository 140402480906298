import React from "react";
import "../index.css";

export default function ScreenshotIcon(props: React.HTMLAttributes<HTMLDivElement>) {
    return (
        <div className={"icon"} {...props}>
            <svg
                className="neon"
                width="800px"
                height="800px"
                viewBox="0 0 24 24"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g id="🔍-Product-Icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="ic_fluent_screenshot_24_regular" fill="#212121" fillRule="nonzero">
                        <path
                            d="M17.25,3 C19.3210678,3 21,4.67893219 21,6.75 L21,17.25 C21,19.3210678 19.3210678,21 17.25,21 L6.75,21 C4.67893219,21 3,19.3210678 3,17.25 L3,6.75 C3,4.67893219 4.67893219,3 6.75,3 L17.25,3 Z M17.25,4.5 L6.75,4.5 C5.50735931,4.5 4.5,5.50735931 4.5,6.75 L4.5,17.25 C4.5,18.4926407 5.50735931,19.5 6.75,19.5 L17.25,19.5 C18.4926407,19.5 19.5,18.4926407 19.5,17.25 L19.5,6.75 C19.5,5.50735931 18.4926407,4.5 17.25,4.5 Z M17.25,13 C17.6642136,13 18,13.3357864 18,13.75 L18,16 C18,17.1046 17.1046,18 16,18 L13.75,18 C13.3357864,18 13,17.6642136 13,17.25 C13,16.8357864 13.3357864,16.5 13.75,16.5 L16,16.5 C16.2761,16.5 16.5,16.2761 16.5,16 L16.5,13.75 C16.5,13.3357864 16.8357864,13 17.25,13 Z M6.75,13 C7.16421356,13 7.5,13.3357864 7.5,13.75 L7.5,16 C7.5,16.2761 7.72386,16.5 8,16.5 L10.25,16.5 C10.6642136,16.5 11,16.8357864 11,17.25 C11,17.6642136 10.6642136,18 10.25,18 L8,18 C6.89543,18 6,17.1046 6,16 L6,13.75 C6,13.3357864 6.33578644,13 6.75,13 Z M8,6 L10.25,6 C10.6642136,6 11,6.33578644 11,6.75 C11,7.12969577 10.7178461,7.44349096 10.3517706,7.49315338 L10.25,7.5 L8,7.5 C7.75454222,7.5 7.5503921,7.67687704 7.50805575,7.91012499 L7.5,8 L7.5,10.25 C7.5,10.6642136 7.16421356,11 6.75,11 C6.37030423,11 6.05650904,10.7178461 6.00684662,10.3517706 L6,10.25 L6,8 C6,6.94563773 6.81587733,6.08183483 7.85073759,6.00548573 L8,6 L10.25,6 L8,6 Z M16,6 C17.1046,6 18,6.89543 18,8 L18,10.25 C18,10.6642136 17.6642136,11 17.25,11 C16.8357864,11 16.5,10.6642136 16.5,10.25 L16.5,8 C16.5,7.72386 16.2761,7.5 16,7.5 L13.75,7.5 C13.3357864,7.5 13,7.16421356 13,6.75 C13,6.33578644 13.3357864,6 13.75,6 L16,6 Z"
                            id="🎨-Color"
                        ></path>
                    </g>
                </g>
            </svg>
        </div>
    );
}
