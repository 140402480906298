/* tslint:disable */
/* eslint-disable */
/**
 * kraken
 * The core component of kraken-project
 *
 * The version of the OpenAPI document: 0.3.5
 * Contact: git@omikron.dev
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FindingSeverity } from './FindingSeverity';
import {
    FindingSeverityFromJSON,
    FindingSeverityFromJSONTyped,
    FindingSeverityToJSON,
} from './FindingSeverity';
import type { HttpServiceCertainty } from './HttpServiceCertainty';
import {
    HttpServiceCertaintyFromJSON,
    HttpServiceCertaintyFromJSONTyped,
    HttpServiceCertaintyToJSON,
} from './HttpServiceCertainty';
import type { SimpleAggregationSource } from './SimpleAggregationSource';
import {
    SimpleAggregationSourceFromJSON,
    SimpleAggregationSourceFromJSONTyped,
    SimpleAggregationSourceToJSON,
} from './SimpleAggregationSource';
import type { SimpleDomain } from './SimpleDomain';
import {
    SimpleDomainFromJSON,
    SimpleDomainFromJSONTyped,
    SimpleDomainToJSON,
} from './SimpleDomain';
import type { SimpleHost } from './SimpleHost';
import {
    SimpleHostFromJSON,
    SimpleHostFromJSONTyped,
    SimpleHostToJSON,
} from './SimpleHost';
import type { SimplePort } from './SimplePort';
import {
    SimplePortFromJSON,
    SimplePortFromJSONTyped,
    SimplePortToJSON,
} from './SimplePort';
import type { SimpleTag } from './SimpleTag';
import {
    SimpleTagFromJSON,
    SimpleTagFromJSONTyped,
    SimpleTagToJSON,
} from './SimpleTag';

/**
 * The full representation of a http service
 * @export
 * @interface FullHttpService
 */
export interface FullHttpService {
    /**
     * The primary key of the http service
     * @type {string}
     * @memberof FullHttpService
     */
    uuid: string;
    /**
     * The http service's name
     * @type {string}
     * @memberof FullHttpService
     */
    name: string;
    /**
     * Optional version of the http service
     * @type {string}
     * @memberof FullHttpService
     */
    version?: string | null;
    /**
     * 
     * @type {SimpleDomain}
     * @memberof FullHttpService
     */
    domain?: SimpleDomain | null;
    /**
     * 
     * @type {SimpleHost}
     * @memberof FullHttpService
     */
    host: SimpleHost;
    /**
     * 
     * @type {SimplePort}
     * @memberof FullHttpService
     */
    port: SimplePort;
    /**
     * The base path the http service is routed on
     * @type {string}
     * @memberof FullHttpService
     */
    basePath: string;
    /**
     * Is this a https service?
     * @type {boolean}
     * @memberof FullHttpService
     */
    tls: boolean;
    /**
     * Does this http service require sni?
     * @type {boolean}
     * @memberof FullHttpService
     */
    sniRequired: boolean;
    /**
     * A comment
     * @type {string}
     * @memberof FullHttpService
     */
    comment: string;
    /**
     * 
     * @type {HttpServiceCertainty}
     * @memberof FullHttpService
     */
    certainty: HttpServiceCertainty;
    /**
     * The workspace this http service is linked to
     * @type {string}
     * @memberof FullHttpService
     */
    workspace: string;
    /**
     * The point in time, the record was created
     * @type {Date}
     * @memberof FullHttpService
     */
    createdAt: Date;
    /**
     * The list of tags this http service has attached to
     * @type {Array<SimpleTag>}
     * @memberof FullHttpService
     */
    tags: Array<SimpleTag>;
    /**
     * 
     * @type {SimpleAggregationSource}
     * @memberof FullHttpService
     */
    sources: SimpleAggregationSource;
    /**
     * 
     * @type {FindingSeverity}
     * @memberof FullHttpService
     */
    severity?: FindingSeverity | null;
}

/**
 * Check if a given object implements the FullHttpService interface.
 */
export function instanceOfFullHttpService(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "uuid" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "host" in value;
    isInstance = isInstance && "port" in value;
    isInstance = isInstance && "basePath" in value;
    isInstance = isInstance && "tls" in value;
    isInstance = isInstance && "sniRequired" in value;
    isInstance = isInstance && "comment" in value;
    isInstance = isInstance && "certainty" in value;
    isInstance = isInstance && "workspace" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "tags" in value;
    isInstance = isInstance && "sources" in value;

    return isInstance;
}

export function FullHttpServiceFromJSON(json: any): FullHttpService {
    return FullHttpServiceFromJSONTyped(json, false);
}

export function FullHttpServiceFromJSONTyped(json: any, ignoreDiscriminator: boolean): FullHttpService {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'uuid': json['uuid'],
        'name': json['name'],
        'version': !exists(json, 'version') ? undefined : json['version'],
        'domain': !exists(json, 'domain') ? undefined : SimpleDomainFromJSON(json['domain']),
        'host': SimpleHostFromJSON(json['host']),
        'port': SimplePortFromJSON(json['port']),
        'basePath': json['base_path'],
        'tls': json['tls'],
        'sniRequired': json['sni_required'],
        'comment': json['comment'],
        'certainty': HttpServiceCertaintyFromJSON(json['certainty']),
        'workspace': json['workspace'],
        'createdAt': (new Date(json['created_at'])),
        'tags': ((json['tags'] as Array<any>).map(SimpleTagFromJSON)),
        'sources': SimpleAggregationSourceFromJSON(json['sources']),
        'severity': !exists(json, 'severity') ? undefined : FindingSeverityFromJSON(json['severity']),
    };
}

export function FullHttpServiceToJSON(value?: FullHttpService | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'uuid': value.uuid,
        'name': value.name,
        'version': value.version,
        'domain': SimpleDomainToJSON(value.domain),
        'host': SimpleHostToJSON(value.host),
        'port': SimplePortToJSON(value.port),
        'base_path': value.basePath,
        'tls': value.tls,
        'sni_required': value.sniRequired,
        'comment': value.comment,
        'certainty': HttpServiceCertaintyToJSON(value.certainty),
        'workspace': value.workspace,
        'created_at': (value.createdAt.toISOString()),
        'tags': ((value.tags as Array<any>).map(SimpleTagToJSON)),
        'sources': SimpleAggregationSourceToJSON(value.sources),
        'severity': FindingSeverityToJSON(value.severity),
    };
}

