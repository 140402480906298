/* tslint:disable */
/* eslint-disable */
/**
 * kraken
 * The core component of kraken-project
 *
 * The version of the OpenAPI document: 0.3.5
 * Contact: git@omikron.dev
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Defines a change
 * 
 * Columns and lines are treated as 1-indexed
 * @export
 * @interface Change
 */
export interface Change {
    /**
     * The text that should be set to the range given by the other values
     * @type {string}
     * @memberof Change
     */
    text: string;
    /**
     * Start of the column
     * @type {number}
     * @memberof Change
     */
    startColumn: number;
    /**
     * End of the column
     * @type {number}
     * @memberof Change
     */
    endColumn: number;
    /**
     * Starting line number
     * @type {number}
     * @memberof Change
     */
    startLine: number;
    /**
     * Ending line number
     * @type {number}
     * @memberof Change
     */
    endLine: number;
}

/**
 * Check if a given object implements the Change interface.
 */
export function instanceOfChange(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "text" in value;
    isInstance = isInstance && "startColumn" in value;
    isInstance = isInstance && "endColumn" in value;
    isInstance = isInstance && "startLine" in value;
    isInstance = isInstance && "endLine" in value;

    return isInstance;
}

export function ChangeFromJSON(json: any): Change {
    return ChangeFromJSONTyped(json, false);
}

export function ChangeFromJSONTyped(json: any, ignoreDiscriminator: boolean): Change {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'text': json['text'],
        'startColumn': json['start_column'],
        'endColumn': json['end_column'],
        'startLine': json['start_line'],
        'endLine': json['end_line'],
    };
}

export function ChangeToJSON(value?: Change | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'text': value.text,
        'start_column': value.startColumn,
        'end_column': value.endColumn,
        'start_line': value.startLine,
        'end_line': value.endLine,
    };
}

