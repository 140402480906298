/* tslint:disable */
/* eslint-disable */
/**
 * kraken
 * The core component of kraken-project
 *
 * The version of the OpenAPI document: 0.3.5
 * Contact: git@omikron.dev
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AttackType } from './AttackType';
import {
    AttackTypeFromJSON,
    AttackTypeFromJSONTyped,
    AttackTypeToJSON,
} from './AttackType';
import type { SimpleUser } from './SimpleUser';
import {
    SimpleUserFromJSON,
    SimpleUserFromJSONTyped,
    SimpleUserToJSON,
} from './SimpleUser';
import type { SimpleWorkspace } from './SimpleWorkspace';
import {
    SimpleWorkspaceFromJSON,
    SimpleWorkspaceFromJSONTyped,
    SimpleWorkspaceToJSON,
} from './SimpleWorkspace';

/**
 * A simple version of an attack
 * @export
 * @interface SimpleAttack
 */
export interface SimpleAttack {
    /**
     * The identifier of the attack
     * @type {string}
     * @memberof SimpleAttack
     */
    uuid: string;
    /**
     * 
     * @type {SimpleWorkspace}
     * @memberof SimpleAttack
     */
    workspace: SimpleWorkspace;
    /**
     * 
     * @type {AttackType}
     * @memberof SimpleAttack
     */
    attackType: AttackType;
    /**
     * 
     * @type {SimpleUser}
     * @memberof SimpleAttack
     */
    startedBy: SimpleUser;
    /**
     * If this is None, the attack is still running
     * @type {Date}
     * @memberof SimpleAttack
     */
    finishedAt?: Date | null;
    /**
     * If this field is set, the attack has finished with an error
     * @type {string}
     * @memberof SimpleAttack
     */
    error?: string | null;
    /**
     * The point in time this attack was started
     * @type {Date}
     * @memberof SimpleAttack
     */
    createdAt: Date;
}

/**
 * Check if a given object implements the SimpleAttack interface.
 */
export function instanceOfSimpleAttack(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "uuid" in value;
    isInstance = isInstance && "workspace" in value;
    isInstance = isInstance && "attackType" in value;
    isInstance = isInstance && "startedBy" in value;
    isInstance = isInstance && "createdAt" in value;

    return isInstance;
}

export function SimpleAttackFromJSON(json: any): SimpleAttack {
    return SimpleAttackFromJSONTyped(json, false);
}

export function SimpleAttackFromJSONTyped(json: any, ignoreDiscriminator: boolean): SimpleAttack {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'uuid': json['uuid'],
        'workspace': SimpleWorkspaceFromJSON(json['workspace']),
        'attackType': AttackTypeFromJSON(json['attack_type']),
        'startedBy': SimpleUserFromJSON(json['started_by']),
        'finishedAt': !exists(json, 'finished_at') ? undefined : (json['finished_at'] === null ? null : new Date(json['finished_at'])),
        'error': !exists(json, 'error') ? undefined : json['error'],
        'createdAt': (new Date(json['created_at'])),
    };
}

export function SimpleAttackToJSON(value?: SimpleAttack | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'uuid': value.uuid,
        'workspace': SimpleWorkspaceToJSON(value.workspace),
        'attack_type': AttackTypeToJSON(value.attackType),
        'started_by': SimpleUserToJSON(value.startedBy),
        'finished_at': value.finishedAt === undefined ? undefined : (value.finishedAt === null ? null : value.finishedAt.toISOString()),
        'error': value.error,
        'created_at': (value.createdAt.toISOString()),
    };
}

