/* tslint:disable */
/* eslint-disable */
/**
 * kraken
 * The core component of kraken-project
 *
 * The version of the OpenAPI document: 0.3.5
 * Contact: git@omikron.dev
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * The certainty of a domain
 * @export
 */
export const DomainCertainty = {
    Unverified: 'Unverified',
    Verified: 'Verified'
} as const;
export type DomainCertainty = typeof DomainCertainty[keyof typeof DomainCertainty];


export function DomainCertaintyFromJSON(json: any): DomainCertainty {
    return DomainCertaintyFromJSONTyped(json, false);
}

export function DomainCertaintyFromJSONTyped(json: any, ignoreDiscriminator: boolean): DomainCertainty {
    return json as DomainCertainty;
}

export function DomainCertaintyToJSON(value?: DomainCertainty | null): any {
    return value as any;
}

