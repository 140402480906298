/* tslint:disable */
/* eslint-disable */
/**
 * kraken
 * The core component of kraken-project
 *
 * The version of the OpenAPI document: 0.3.5
 * Contact: git@omikron.dev
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SimpleQueryUnhashedResult } from './SimpleQueryUnhashedResult';
import {
    SimpleQueryUnhashedResultFromJSON,
    SimpleQueryUnhashedResultFromJSONTyped,
    SimpleQueryUnhashedResultToJSON,
} from './SimpleQueryUnhashedResult';

/**
 * 
 * @export
 * @interface SourceAttackResultOneOf2
 */
export interface SourceAttackResultOneOf2 {
    /**
     * 
     * @type {string}
     * @memberof SourceAttackResultOneOf2
     */
    attackType: SourceAttackResultOneOf2AttackTypeEnum;
    /**
     * The [`AttackType::QueryUnhashed`] and its results
     * @type {Array<SimpleQueryUnhashedResult>}
     * @memberof SourceAttackResultOneOf2
     */
    results: Array<SimpleQueryUnhashedResult>;
}


/**
 * @export
 */
export const SourceAttackResultOneOf2AttackTypeEnum = {
    QueryDehashed: 'QueryDehashed'
} as const;
export type SourceAttackResultOneOf2AttackTypeEnum = typeof SourceAttackResultOneOf2AttackTypeEnum[keyof typeof SourceAttackResultOneOf2AttackTypeEnum];


/**
 * Check if a given object implements the SourceAttackResultOneOf2 interface.
 */
export function instanceOfSourceAttackResultOneOf2(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "attackType" in value;
    isInstance = isInstance && "results" in value;

    return isInstance;
}

export function SourceAttackResultOneOf2FromJSON(json: any): SourceAttackResultOneOf2 {
    return SourceAttackResultOneOf2FromJSONTyped(json, false);
}

export function SourceAttackResultOneOf2FromJSONTyped(json: any, ignoreDiscriminator: boolean): SourceAttackResultOneOf2 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'attackType': json['attack_type'],
        'results': ((json['results'] as Array<any>).map(SimpleQueryUnhashedResultFromJSON)),
    };
}

export function SourceAttackResultOneOf2ToJSON(value?: SourceAttackResultOneOf2 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'attack_type': value.attackType,
        'results': ((value.results as Array<any>).map(SimpleQueryUnhashedResultToJSON)),
    };
}

