/* tslint:disable */
/* eslint-disable */
/**
 * kraken
 * The core component of kraken-project
 *
 * The version of the OpenAPI document: 0.3.5
 * Contact: git@omikron.dev
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * The permission of a user
 * @export
 */
export const UserPermission = {
    ReadOnly: 'ReadOnly',
    Default: 'Default',
    Admin: 'Admin'
} as const;
export type UserPermission = typeof UserPermission[keyof typeof UserPermission];


export function UserPermissionFromJSON(json: any): UserPermission {
    return UserPermissionFromJSONTyped(json, false);
}

export function UserPermissionFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserPermission {
    return json as UserPermission;
}

export function UserPermissionToJSON(value?: UserPermission | null): any {
    return value as any;
}

