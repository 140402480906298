/* tslint:disable */
/* eslint-disable */
/**
 * kraken
 * The core component of kraken-project
 *
 * The version of the OpenAPI document: 0.3.5
 * Contact: git@omikron.dev
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiErrorResponse,
  CreateLeechRequest,
  LeechConfig,
  ListLeeches,
  SimpleLeech,
  UpdateLeechRequest,
  UuidResponse,
} from '../models';
import {
    ApiErrorResponseFromJSON,
    ApiErrorResponseToJSON,
    CreateLeechRequestFromJSON,
    CreateLeechRequestToJSON,
    LeechConfigFromJSON,
    LeechConfigToJSON,
    ListLeechesFromJSON,
    ListLeechesToJSON,
    SimpleLeechFromJSON,
    SimpleLeechToJSON,
    UpdateLeechRequestFromJSON,
    UpdateLeechRequestToJSON,
    UuidResponseFromJSON,
    UuidResponseToJSON,
} from '../models';

export interface CreateLeechOperationRequest {
    createLeechRequest: CreateLeechRequest;
}

export interface DeleteLeechRequest {
    uuid: string;
}

export interface GenLeechConfigRequest {
    uuid: string;
}

export interface GetLeechRequest {
    uuid: string;
}

export interface UpdateLeechOperationRequest {
    uuid: string;
    updateLeechRequest: UpdateLeechRequest;
}

/**
 * 
 */
export class LeechManagementApi extends runtime.BaseAPI {

    /**
     * The `name` parameter must be unique.  `address` must be a valid address including a scheme and port. Currently only https and http are supported as scheme.
     * Create a leech
     */
    async createLeechRaw(requestParameters: CreateLeechOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UuidResponse>> {
        if (requestParameters.createLeechRequest === null || requestParameters.createLeechRequest === undefined) {
            throw new runtime.RequiredError('createLeechRequest','Required parameter requestParameters.createLeechRequest was null or undefined when calling createLeech.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/admin/leeches`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateLeechRequestToJSON(requestParameters.createLeechRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UuidResponseFromJSON(jsonValue));
    }

    /**
     * The `name` parameter must be unique.  `address` must be a valid address including a scheme and port. Currently only https and http are supported as scheme.
     * Create a leech
     */
    async createLeech(requestParameters: CreateLeechOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UuidResponse> {
        const response = await this.createLeechRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete a leech by its uuid
     */
    async deleteLeechRaw(requestParameters: DeleteLeechRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling deleteLeech.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/admin/leeches/{uuid}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a leech by its uuid
     */
    async deleteLeech(requestParameters: DeleteLeechRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteLeechRaw(requestParameters, initOverrides);
    }

    /**
     * Generate a new config for the leech
     */
    async genLeechConfigRaw(requestParameters: GenLeechConfigRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LeechConfig>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling genLeechConfig.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/admin/leeches/{uuid}/cert`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LeechConfigFromJSON(jsonValue));
    }

    /**
     * Generate a new config for the leech
     */
    async genLeechConfig(requestParameters: GenLeechConfigRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LeechConfig> {
        const response = await this.genLeechConfigRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieve all leeches
     */
    async getAllLeechesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListLeeches>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/admin/leeches`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListLeechesFromJSON(jsonValue));
    }

    /**
     * Retrieve all leeches
     */
    async getAllLeeches(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListLeeches> {
        const response = await this.getAllLeechesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Retrieve a leech by its id
     */
    async getLeechRaw(requestParameters: GetLeechRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SimpleLeech>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling getLeech.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/admin/leeches/{uuid}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SimpleLeechFromJSON(jsonValue));
    }

    /**
     * Retrieve a leech by its id
     */
    async getLeech(requestParameters: GetLeechRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SimpleLeech> {
        const response = await this.getLeechRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * All parameter are optional, but at least one of them must be specified.  `address` must be a valid address including a scheme and port. Currently only https and http are supported as scheme.
     * Update a leech by its id
     */
    async updateLeechRaw(requestParameters: UpdateLeechOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling updateLeech.');
        }

        if (requestParameters.updateLeechRequest === null || requestParameters.updateLeechRequest === undefined) {
            throw new runtime.RequiredError('updateLeechRequest','Required parameter requestParameters.updateLeechRequest was null or undefined when calling updateLeech.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/admin/leeches/{uuid}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateLeechRequestToJSON(requestParameters.updateLeechRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * All parameter are optional, but at least one of them must be specified.  `address` must be a valid address including a scheme and port. Currently only https and http are supported as scheme.
     * Update a leech by its id
     */
    async updateLeech(requestParameters: UpdateLeechOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateLeechRaw(requestParameters, initOverrides);
    }

}
