type BookToolIconProps = {};

export default function BookToolIcon(props: BookToolIconProps) {
    return (
        <div className={"icon"} {...props}>
            <svg
                className="neon"
                fill="none"
                stroke="#000"
                strokeLinecap="round"
                strokeLinejoin="round"
                width="800px"
                height="800px"
                strokeWidth="32px"
                viewBox="0 0 512 512"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path d="M 256 160 c 16 -63.16 76.43 -95.41 208 -96 a 15.94 15.94 0 0 1 16 16 V 368 a 16 16 0 0 1 -16 16 C 390 384 352 393 336 398 M 32 366.07 V 80 A 15.94 15.94 0 0 1 48 64 C 179.57 64.59 240 96.84 256 160" />
                <line x1="256" y1="160" x2="256" y2="240" />
                <g transform="scale(10 10) translate(4 6.5)" strokeWidth="0" fill="#000">
                    <path d="M22.164,24.263l1.485-1.484l-2.693-2.694l-1.484,1.486c-0.864-0.572-1.828-0.999-2.858-1.267v-2.22h-4.781   v2.22c-1.053,0.272-2.039,0.711-2.917,1.302l-1.425-1.425l-2.693,2.692l1.449,1.449c-0.554,0.849-0.97,1.793-1.23,2.8H2.796v4.776   h2.218c0.267,1.031,0.694,1.998,1.267,2.862l-1.485,1.484l2.693,2.691l1.485-1.484c0.866,0.573,1.834,1.002,2.867,1.269v2.216   h4.761v-2.216c1.01-0.26,1.957-0.678,2.808-1.231l1.544,1.543l2.693-2.691l-1.521-1.522c0.594-0.883,1.034-1.876,1.306-2.934h2.215   v-4.747h-2.215C23.167,26.103,22.739,25.131,22.164,24.263z M14.223,33.428c-2.163,0-3.917-1.754-3.917-3.917   s1.754-3.917,3.917-3.917s3.917,1.754,3.917,3.917S16.386,33.428,14.223,33.428z" />
                </g>
            </svg>
        </div>
    );
}
