/* tslint:disable */
/* eslint-disable */
/**
 * kraken
 * The core component of kraken-project
 *
 * The version of the OpenAPI document: 0.3.5
 * Contact: git@omikron.dev
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * A result for a subdomain enumeration using bruteforce DNS requests
 * @export
 * @interface WsMessageOneOf6
 */
export interface WsMessageOneOf6 {
    /**
     * The corresponding id of the attack
     * @type {string}
     * @memberof WsMessageOneOf6
     */
    attackUuid: string;
    /**
     * The source address that was queried
     * @type {string}
     * @memberof WsMessageOneOf6
     */
    source: string;
    /**
     * The destination address that was returned
     * @type {string}
     * @memberof WsMessageOneOf6
     */
    destination: string;
    /**
     * 
     * @type {string}
     * @memberof WsMessageOneOf6
     */
    type: WsMessageOneOf6TypeEnum;
}


/**
 * @export
 */
export const WsMessageOneOf6TypeEnum = {
    BruteforceSubdomainsResult: 'BruteforceSubdomainsResult'
} as const;
export type WsMessageOneOf6TypeEnum = typeof WsMessageOneOf6TypeEnum[keyof typeof WsMessageOneOf6TypeEnum];


/**
 * Check if a given object implements the WsMessageOneOf6 interface.
 */
export function instanceOfWsMessageOneOf6(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "attackUuid" in value;
    isInstance = isInstance && "source" in value;
    isInstance = isInstance && "destination" in value;
    isInstance = isInstance && "type" in value;

    return isInstance;
}

export function WsMessageOneOf6FromJSON(json: any): WsMessageOneOf6 {
    return WsMessageOneOf6FromJSONTyped(json, false);
}

export function WsMessageOneOf6FromJSONTyped(json: any, ignoreDiscriminator: boolean): WsMessageOneOf6 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'attackUuid': json['attack_uuid'],
        'source': json['source'],
        'destination': json['destination'],
        'type': json['type'],
    };
}

export function WsMessageOneOf6ToJSON(value?: WsMessageOneOf6 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'attack_uuid': value.attackUuid,
        'source': value.source,
        'destination': value.destination,
        'type': value.type,
    };
}

