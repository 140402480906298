/* tslint:disable */
/* eslint-disable */
/**
 * kraken
 * The core component of kraken-project
 *
 * The version of the OpenAPI document: 0.3.5
 * Contact: git@omikron.dev
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PortCertainty } from './PortCertainty';
import {
    PortCertaintyFromJSON,
    PortCertaintyFromJSONTyped,
    PortCertaintyToJSON,
} from './PortCertainty';
import type { PortProtocol } from './PortProtocol';
import {
    PortProtocolFromJSON,
    PortProtocolFromJSONTyped,
    PortProtocolToJSON,
} from './PortProtocol';

/**
 * The simple representation of a port
 * @export
 * @interface SimplePort
 */
export interface SimplePort {
    /**
     * Uuid of the port
     * @type {string}
     * @memberof SimplePort
     */
    uuid: string;
    /**
     * Port number
     * @type {number}
     * @memberof SimplePort
     */
    port: number;
    /**
     * 
     * @type {PortProtocol}
     * @memberof SimplePort
     */
    protocol: PortProtocol;
    /**
     * 
     * @type {PortCertainty}
     * @memberof SimplePort
     */
    certainty: PortCertainty;
    /**
     * The host this port is assigned to
     * @type {string}
     * @memberof SimplePort
     */
    host: string;
    /**
     * A comment to the port
     * @type {string}
     * @memberof SimplePort
     */
    comment: string;
    /**
     * The workspace this port is linked to
     * @type {string}
     * @memberof SimplePort
     */
    workspace: string;
    /**
     * The point in time, the record was created
     * @type {Date}
     * @memberof SimplePort
     */
    createdAt: Date;
}

/**
 * Check if a given object implements the SimplePort interface.
 */
export function instanceOfSimplePort(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "uuid" in value;
    isInstance = isInstance && "port" in value;
    isInstance = isInstance && "protocol" in value;
    isInstance = isInstance && "certainty" in value;
    isInstance = isInstance && "host" in value;
    isInstance = isInstance && "comment" in value;
    isInstance = isInstance && "workspace" in value;
    isInstance = isInstance && "createdAt" in value;

    return isInstance;
}

export function SimplePortFromJSON(json: any): SimplePort {
    return SimplePortFromJSONTyped(json, false);
}

export function SimplePortFromJSONTyped(json: any, ignoreDiscriminator: boolean): SimplePort {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'uuid': json['uuid'],
        'port': json['port'],
        'protocol': PortProtocolFromJSON(json['protocol']),
        'certainty': PortCertaintyFromJSON(json['certainty']),
        'host': json['host'],
        'comment': json['comment'],
        'workspace': json['workspace'],
        'createdAt': (new Date(json['created_at'])),
    };
}

export function SimplePortToJSON(value?: SimplePort | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'uuid': value.uuid,
        'port': value.port,
        'protocol': PortProtocolToJSON(value.protocol),
        'certainty': PortCertaintyToJSON(value.certainty),
        'host': value.host,
        'comment': value.comment,
        'workspace': value.workspace,
        'created_at': (value.createdAt.toISOString()),
    };
}

