/* tslint:disable */
/* eslint-disable */
/**
 * kraken
 * The core component of kraken-project
 *
 * The version of the OpenAPI document: 0.3.5
 * Contact: git@omikron.dev
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ManualHttpServiceCertainty } from './ManualHttpServiceCertainty';
import {
    ManualHttpServiceCertaintyFromJSON,
    ManualHttpServiceCertaintyFromJSONTyped,
    ManualHttpServiceCertaintyToJSON,
} from './ManualHttpServiceCertainty';
import type { PortProtocol } from './PortProtocol';
import {
    PortProtocolFromJSON,
    PortProtocolFromJSONTyped,
    PortProtocolToJSON,
} from './PortProtocol';

/**
 * The request to manually add a http service
 * @export
 * @interface CreateHttpServiceRequest
 */
export interface CreateHttpServiceRequest {
    /**
     * The service's name
     * @type {string}
     * @memberof CreateHttpServiceRequest
     */
    name: string;
    /**
     * Optional version of the http service
     * @type {string}
     * @memberof CreateHttpServiceRequest
     */
    version?: string | null;
    /**
     * The service's domain
     * @type {string}
     * @memberof CreateHttpServiceRequest
     */
    domain?: string | null;
    /**
     * The service's ip address
     * @type {string}
     * @memberof CreateHttpServiceRequest
     */
    ipAddr: string;
    /**
     * The service's port
     * @type {number}
     * @memberof CreateHttpServiceRequest
     */
    port: number;
    /**
     * 
     * @type {PortProtocol}
     * @memberof CreateHttpServiceRequest
     */
    portProtocol: PortProtocol;
    /**
     * 
     * @type {ManualHttpServiceCertainty}
     * @memberof CreateHttpServiceRequest
     */
    certainty: ManualHttpServiceCertainty;
    /**
     * The base path the service is routed on
     * 
     * (Should default to "/")
     * @type {string}
     * @memberof CreateHttpServiceRequest
     */
    basePath: string;
    /**
     * Is this a https service?
     * @type {boolean}
     * @memberof CreateHttpServiceRequest
     */
    tls: boolean;
    /**
     * Does this service require sni?
     * @type {boolean}
     * @memberof CreateHttpServiceRequest
     */
    sniRequire: boolean;
}

/**
 * Check if a given object implements the CreateHttpServiceRequest interface.
 */
export function instanceOfCreateHttpServiceRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "ipAddr" in value;
    isInstance = isInstance && "port" in value;
    isInstance = isInstance && "portProtocol" in value;
    isInstance = isInstance && "certainty" in value;
    isInstance = isInstance && "basePath" in value;
    isInstance = isInstance && "tls" in value;
    isInstance = isInstance && "sniRequire" in value;

    return isInstance;
}

export function CreateHttpServiceRequestFromJSON(json: any): CreateHttpServiceRequest {
    return CreateHttpServiceRequestFromJSONTyped(json, false);
}

export function CreateHttpServiceRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateHttpServiceRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'version': !exists(json, 'version') ? undefined : json['version'],
        'domain': !exists(json, 'domain') ? undefined : json['domain'],
        'ipAddr': json['ip_addr'],
        'port': json['port'],
        'portProtocol': PortProtocolFromJSON(json['port_protocol']),
        'certainty': ManualHttpServiceCertaintyFromJSON(json['certainty']),
        'basePath': json['base_path'],
        'tls': json['tls'],
        'sniRequire': json['sni_require'],
    };
}

export function CreateHttpServiceRequestToJSON(value?: CreateHttpServiceRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'version': value.version,
        'domain': value.domain,
        'ip_addr': value.ipAddr,
        'port': value.port,
        'port_protocol': PortProtocolToJSON(value.portProtocol),
        'certainty': ManualHttpServiceCertaintyToJSON(value.certainty),
        'base_path': value.basePath,
        'tls': value.tls,
        'sni_require': value.sniRequire,
    };
}

