/* tslint:disable */
/* eslint-disable */
/**
 * kraken
 * The core component of kraken-project
 *
 * The version of the OpenAPI document: 0.3.5
 * Contact: git@omikron.dev
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SimpleFindingCategory } from './SimpleFindingCategory';
import {
    SimpleFindingCategoryFromJSON,
    SimpleFindingCategoryFromJSONTyped,
    SimpleFindingCategoryToJSON,
} from './SimpleFindingCategory';

/**
 * The response to a request to retrieve all finding categories
 * @export
 * @interface ListFindingCategories
 */
export interface ListFindingCategories {
    /**
     * List of finding categories
     * @type {Array<SimpleFindingCategory>}
     * @memberof ListFindingCategories
     */
    categories: Array<SimpleFindingCategory>;
}

/**
 * Check if a given object implements the ListFindingCategories interface.
 */
export function instanceOfListFindingCategories(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "categories" in value;

    return isInstance;
}

export function ListFindingCategoriesFromJSON(json: any): ListFindingCategories {
    return ListFindingCategoriesFromJSONTyped(json, false);
}

export function ListFindingCategoriesFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListFindingCategories {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'categories': ((json['categories'] as Array<any>).map(SimpleFindingCategoryFromJSON)),
    };
}

export function ListFindingCategoriesToJSON(value?: ListFindingCategories | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'categories': ((value.categories as Array<any>).map(SimpleFindingCategoryToJSON)),
    };
}

