/* tslint:disable */
/* eslint-disable */
/**
 * kraken
 * The core component of kraken-project
 *
 * The version of the OpenAPI document: 0.3.5
 * Contact: git@omikron.dev
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * A service was deleted
 * @export
 * @interface WsMessageOneOf23
 */
export interface WsMessageOneOf23 {
    /**
     * The workspace this service is related to
     * @type {string}
     * @memberof WsMessageOneOf23
     */
    workspace: string;
    /**
     * The uuid of the deleted service
     * @type {string}
     * @memberof WsMessageOneOf23
     */
    service: string;
    /**
     * 
     * @type {string}
     * @memberof WsMessageOneOf23
     */
    type: WsMessageOneOf23TypeEnum;
}


/**
 * @export
 */
export const WsMessageOneOf23TypeEnum = {
    DeletedService: 'DeletedService'
} as const;
export type WsMessageOneOf23TypeEnum = typeof WsMessageOneOf23TypeEnum[keyof typeof WsMessageOneOf23TypeEnum];


/**
 * Check if a given object implements the WsMessageOneOf23 interface.
 */
export function instanceOfWsMessageOneOf23(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "workspace" in value;
    isInstance = isInstance && "service" in value;
    isInstance = isInstance && "type" in value;

    return isInstance;
}

export function WsMessageOneOf23FromJSON(json: any): WsMessageOneOf23 {
    return WsMessageOneOf23FromJSONTyped(json, false);
}

export function WsMessageOneOf23FromJSONTyped(json: any, ignoreDiscriminator: boolean): WsMessageOneOf23 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'workspace': json['workspace'],
        'service': json['service'],
        'type': json['type'],
    };
}

export function WsMessageOneOf23ToJSON(value?: WsMessageOneOf23 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'workspace': value.workspace,
        'service': value.service,
        'type': value.type,
    };
}

