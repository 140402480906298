/* tslint:disable */
/* eslint-disable */
/**
 * kraken
 * The core component of kraken-project
 *
 * The version of the OpenAPI document: 0.3.5
 * Contact: git@omikron.dev
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FullOauthClient } from './FullOauthClient';
import {
    FullOauthClientFromJSON,
    FullOauthClientFromJSONTyped,
    FullOauthClientToJSON,
} from './FullOauthClient';

/**
 * List all oauth applications
 * @export
 * @interface ListOauthApplications
 */
export interface ListOauthApplications {
    /**
     * The list of applications
     * @type {Array<FullOauthClient>}
     * @memberof ListOauthApplications
     */
    apps: Array<FullOauthClient>;
}

/**
 * Check if a given object implements the ListOauthApplications interface.
 */
export function instanceOfListOauthApplications(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "apps" in value;

    return isInstance;
}

export function ListOauthApplicationsFromJSON(json: any): ListOauthApplications {
    return ListOauthApplicationsFromJSONTyped(json, false);
}

export function ListOauthApplicationsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListOauthApplications {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'apps': ((json['apps'] as Array<any>).map(FullOauthClientFromJSON)),
    };
}

export function ListOauthApplicationsToJSON(value?: ListOauthApplications | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'apps': ((value.apps as Array<any>).map(FullOauthClientToJSON)),
    };
}

