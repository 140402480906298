/* tslint:disable */
/* eslint-disable */
/**
 * kraken
 * The core component of kraken-project
 *
 * The version of the OpenAPI document: 0.3.5
 * Contact: git@omikron.dev
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FindingSeverity } from './FindingSeverity';
import {
    FindingSeverityFromJSON,
    FindingSeverityFromJSONTyped,
    FindingSeverityToJSON,
} from './FindingSeverity';

/**
 * The request to create a new finding
 * @export
 * @interface CreateFindingRequest
 */
export interface CreateFindingRequest {
    /**
     * Name of the new finding definition
     * 
     * This must be unique
     * @type {string}
     * @memberof CreateFindingRequest
     */
    definition: string;
    /**
     * 
     * @type {FindingSeverity}
     * @memberof CreateFindingRequest
     */
    severity: FindingSeverity;
    /**
     * Notes about the finding included in the data export
     * 
     * May be used for documenting details about the finding
     * used to generate reports outside of kraken.
     * @type {string}
     * @memberof CreateFindingRequest
     */
    exportDetails: string;
    /**
     * Notes about the finding provided by the user
     * 
     * May be used for documenting command invocation or other information
     * that are provided by the user
     * @type {string}
     * @memberof CreateFindingRequest
     */
    userDetails: string;
    /**
     * A screenshot
     * 
     * The file must have been uploaded through the image upload.
     * @type {string}
     * @memberof CreateFindingRequest
     */
    screenshot?: string | null;
    /**
     * A log file
     * @type {string}
     * @memberof CreateFindingRequest
     */
    logFile?: string | null;
    /**
     * List of categories
     * @type {Array<string>}
     * @memberof CreateFindingRequest
     */
    categories: Array<string>;
}

/**
 * Check if a given object implements the CreateFindingRequest interface.
 */
export function instanceOfCreateFindingRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "definition" in value;
    isInstance = isInstance && "severity" in value;
    isInstance = isInstance && "exportDetails" in value;
    isInstance = isInstance && "userDetails" in value;
    isInstance = isInstance && "categories" in value;

    return isInstance;
}

export function CreateFindingRequestFromJSON(json: any): CreateFindingRequest {
    return CreateFindingRequestFromJSONTyped(json, false);
}

export function CreateFindingRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateFindingRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'definition': json['definition'],
        'severity': FindingSeverityFromJSON(json['severity']),
        'exportDetails': json['export_details'],
        'userDetails': json['user_details'],
        'screenshot': !exists(json, 'screenshot') ? undefined : json['screenshot'],
        'logFile': !exists(json, 'log_file') ? undefined : json['log_file'],
        'categories': json['categories'],
    };
}

export function CreateFindingRequestToJSON(value?: CreateFindingRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'definition': value.definition,
        'severity': FindingSeverityToJSON(value.severity),
        'export_details': value.exportDetails,
        'user_details': value.userDetails,
        'screenshot': value.screenshot,
        'log_file': value.logFile,
        'categories': value.categories,
    };
}

